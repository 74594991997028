import React from 'react';
import './Toast.scss';

export type ToastVariants =
    | 'default'
    | 'success'
    | 'warning'
    | 'error'
    | 'loading'
    | undefined;

interface Props {
    variant: ToastVariants;
    isOpen?: boolean;
    handleCancel?: () => void;
}

export const Toast: React.FC<Props> = (props) => {
    const {
        variant = 'default',
        children,
        handleCancel,
        isOpen = true,
        ...rest
    } = props;
    return (
        <div
            className={`covision-toast ${variant} ${isOpen ? 'show' : ''}`}
            {...rest}
        >
            <div className="wrap">
                <div className="icon" />
                <span>{children}</span>
                {variant !== 'loading' ? (
                    <div
                        role="button"
                        aria-label="close"
                        className="close-icon"
                        onClick={handleCancel}
                        onKeyPress={() => undefined}
                        tabIndex={0}
                    />
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

export default Toast;
