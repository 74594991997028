import React, { ReactNode } from 'react';
import './Tooltip.scss';

/**
 * Loader: To show loader with or without label
 *
 * Example:
 *
 * <Tooltip variant='default'>This is a default tooltip</Tooltip>
 *
 * variants: are given in below Props type
 *
 */

type Props = {
    variant?:
        | 'default'
        | 'north'
        | 'northeast'
        | 'east'
        | 'southeast'
        | 'south'
        | 'southwest'
        | 'west'
        | 'northwest';
    tooltipLabel: string | ReactNode;
};

export const Tooltip: React.FC<Props> = (props) => {
    const { variant = 'default', tooltipLabel, ...rest } = props;
    return (
        <div className={`custom-tooltip ${variant}`} {...rest}>
            <p>{tooltipLabel}</p>
        </div>
    );
};
