import React from 'react';
import OverlayTooltip from 'uikit/tooltips/OverlayToolTip/OverlayTooltip';
import InfoIcon from 'images/actions/info.svg';
import './Labels.scss';
import { Box } from '@primer/components';

/**
 * Label: to create a label
 *
 * Example:
 *
 * <Label variant='default' label='Saving import' />
 *
 * available variants:- "default" | "outline" | "solid"
 *
 */

type Props = {
    variant?:
        | 'default'
        | 'normal'
        | 'outline'
        | 'solid'
        | 'label-for-input'
        | 'label-secondary'
        | 'covision-input-label'
        | 'error';
    label: string | React.ReactNode;
    labelClass?: 'with-padding' | 'covision-input-label' | string;
    style?: Object;
    textStyle?: Object;
    infoText?: string;
};

const Label: React.FC<Props> = (props) => {
    const {
        variant = 'default',
        label,
        labelClass,
        infoText,
        textStyle = {},
        ...rest
    } = props;
    return (
        <Box
            display="flex"
            alignItems={infoText ? 'center' : 'flex-start'}
            className={`label ${variant} ${labelClass ?? ''}`}
            {...rest}
        >
            <span style={textStyle}>{label}</span>
            {infoText && (
                <Box marginTop="-18px" marginLeft="3px">
                    <OverlayTooltip label={infoText} placement="top">
                        <img src={InfoIcon} alt="icon" className="info-icon" />
                    </OverlayTooltip>
                </Box>
            )}
        </Box>
    );
};

export default Label;
