import React, { ReactNode } from 'react';
import { components, ControlProps, Props } from 'react-select';
import OverlayTooltip from 'uikit/tooltips/OverlayToolTip/OverlayTooltip';

type CustomValueControlType = {
    tooltipLabel?: string | ReactNode;
};
const CustomValueControl: React.FC<ControlProps> = (valueControlProps) => {
    const { selectProps } = valueControlProps;
    const { tooltipLabel } = selectProps as Props & CustomValueControlType;
    return (
        <OverlayTooltip label={tooltipLabel}>
            <components.Control {...valueControlProps} />
        </OverlayTooltip>
    );
};

export default CustomValueControl;
