import React from 'react';
import { DropdownIndicatorProps, components } from 'react-select';
import DropdownIcon from 'images/single-select-dropdown-arrow.svg';
import DropdownSingleArrowIcon from 'images/single-select-dropdown-single-arrow.svg';
import { Box } from '@primer/components';

export type DropdownIndicatorCustomPropType = {
    isSingleArrow?: boolean;
};
const DropdownIndicator: React.FC<
    DropdownIndicatorProps & DropdownIndicatorCustomPropType
> = (dropdownProps) => {
    const { isSingleArrow } = dropdownProps;
    return (
        <components.DropdownIndicator {...dropdownProps}>
            <Box paddingRight="5px" display="flex">
                <img
                    src={isSingleArrow ? DropdownSingleArrowIcon : DropdownIcon}
                    alt="icon"
                />
            </Box>
        </components.DropdownIndicator>
    );
};

export default DropdownIndicator;
