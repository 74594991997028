import { Box } from '@primer/components';
import React, { createContext, useState, useContext, useEffect } from 'react';
import ReactDom from 'react-dom';
import Toast from 'uikit/alerts/Toast/Toast';

import './toast.scss';

export const ToastContainer = ({ toasts = [], handleClose }) =>
    ReactDom.createPortal(
        <>
            {toasts.map((toast, index) => {
                const { id, variant, content } = toast;
                return (
                    <Box
                        key={id}
                        id={`toast-item-${index}`}
                        className="toast-provider animated fadeInDown"
                    >
                        <Toast
                            variant={variant}
                            handleCancel={() => handleClose(id)}
                        >
                            {content}
                        </Toast>
                    </Box>
                );
            })}
        </>,
        document.getElementById('toast-portal')
    );

export const ToastContext = createContext();

const TOAST_PENDING_QUEUE = [];
const TOAST_ALLOWED_LENGTH = 10;

export const ToastProvider = ({ children }) => {
    const [toastList, setToastList] = useState([]);
    const [isDisableHideInterval, setIsDisableHideInterval] = useState(false);

    useEffect(() => {
        if (isDisableHideInterval) return;
        if (toastList.length > 0) {
            const timer = setTimeout(() => {
                setToastList((prev) => prev.slice(1));
                addToastFromPendingQueue();
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [toastList, isDisableHideInterval]);

    const showToast = (
        content = '',
        variant = 'success',
        disableHideInterval = false
    ) => {
        setIsDisableHideInterval(disableHideInterval);

        const payload = {
            id: new Date().getTime(),
            content,
            variant,
        };

        if (toastList.length < TOAST_ALLOWED_LENGTH) {
            setToastList((prevList) => [...prevList, payload]);
            return payload;
        }
        TOAST_PENDING_QUEUE.push(payload);
    };

    const addToastFromPendingQueue = () => {
        const lastToastPendingItem = TOAST_PENDING_QUEUE.pop();
        if (lastToastPendingItem) {
            showToast(lastToastPendingItem);
        }
    };

    const handleClose = (id) => {
        setToastList((prevList) => prevList.filter((t) => t.id !== id));
        addToastFromPendingQueue();
    };

    return (
        <ToastContext.Provider value={{ showToast, handleClose }}>
            {children}
            <ToastContainer toasts={toastList} handleClose={handleClose} />
        </ToastContext.Provider>
    );
};

export const useToast = () => {
    const toastHelpers = useContext(ToastContext);
    return toastHelpers;
};
