import React, { ReactNode } from 'react';
import { Box } from '@primer/components';
import { OverlayTrigger } from 'react-bootstrap';
import { Tooltip } from '../Tooltip';
import './OverlayTooltip.scss';

export type TooltipPlacement = 'top' | 'bottom';
interface Props {
    label: string | ReactNode;
    placement?: TooltipPlacement;
}

const OverlayTooltip: React.FC<Props> = ({
    label,
    children,
    placement = 'bottom',
    ...rest
}) => (
    <div className="overlay-tooltip">
        <OverlayTrigger
            placement={placement}
            overlay={
                label ? (
                    <Box
                        paddingTop={placement === 'bottom' ? '10px' : '0px'}
                        paddingBottom={placement === 'bottom' ? '0px' : '10px'}
                    >
                        <Tooltip
                            variant={placement === 'bottom' ? 'south' : 'north'}
                            tooltipLabel={label}
                        />
                    </Box>
                ) : (
                    <div />
                )
            }
            {...rest}
            rootClose
        >
            <div>{children}</div>
        </OverlayTrigger>
    </div>
);

export default OverlayTooltip;
