import { produce } from 'immer';
import * as types from './breakoutTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    breakouts: [],
    breakoutsCount: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_BREAKOUTS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_BREAKOUTS.SUCCESS]: produce((draft, { total, breakouts }) => {
        draft.breakouts = breakouts;
        draft.breakoutsCount = total;
        draft.loading = false;
    }),
    [types.GET_BREAKOUTS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_BREAKOUTS]: produce((draft) => {
        draft.breakouts = [];
        draft.breakoutsCount = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
