import { produce } from 'immer';
import * as types from './appVersionTypes';
import createReducer from '../../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_APP_VERSIONS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_APP_VERSIONS.SUCCESS]: produce((draft, { total, records }) => {
        draft.records = records.map((appVersion) => ({
            ...appVersion,
            isRequired: appVersion.isRequired ? 'Yes' : 'No',
        }));
        draft.totalRecords = total;
        draft.loading = false;
    }),
    [types.GET_APP_VERSIONS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_APP_VERSIONS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
