import axios from 'axios';

// downloadFileFromBlobData: download blob data as file
export const downloadFileFromBlobData = (blob: string, fileName: string) => {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = blob;
    // link.setAttribute('download', 'sample.xlsx');
    // uncomment if only csv file need to open in new tag
    // if (blob.includes('.csv')) {
    link.setAttribute('target', '_blank');
    // }
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    //   // 5. Clean up and remove the link
    // link.parentNode.removeChild(link);
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

// getBlobDataFromKey: fetch file from given url as blob
export const getBlobDataFromKey = (_uri: string): Promise<string> =>
    new Promise((resolve, reject) => {
        axios({
            url: _uri,
            method: 'GET',
            responseType: 'blob',
        })
            .then((response) =>
                window.URL.createObjectURL(new Blob([response.data]))
            )
            .then((_finalPdf) => {
                resolve(_finalPdf);
            })
            .catch((error) => {
                reject(error);
            });
    });

// downloadFileFromKey: function to download file from given url
export const downloadFileFromUrl = (
    _uri: string,
    fileName: string
): Promise<boolean> =>
    new Promise((resolve) => {
        downloadFileFromBlobData(_uri, fileName);
        resolve(true);
    });
