const localStorageKeys = {
    ACCESS_TOKEN: 'access-token',
    ROLE: 'user-role',
    USERID: 'user-id',
    USER_DATA: 'user-data',
    PARTICIPANT_VIEW: 'participant-view',
    ADMIN_VIEW_ACCESSIBLE: 'admin-view-accesible',
    REDIRECT_URL: 'url-for-redirection',
    MY_AGENDA_SELECTED_DATE: 'my-agenda-selected-date',
    EVENT_SPECIFIC_URL: 'event-specific-url',
    LOGIN_TYPE: 'login-type',
    FORM_ACCESS_TOKEN: 'form-access-token',
};

export default localStorageKeys;
