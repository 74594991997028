/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-underscore-dangle */
import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import persistConfig from './persistConfig';
import rootReducer from './rootReducer';

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = (initialState) => {
    const middlewares = [thunkMiddleware];
    const store = createStore(
        persistedReducer,
        initialState,
        compose(
            applyMiddleware(...middlewares),
            window.__REDUX_DEVTOOLS_EXTENSION__
                ? window.window.__REDUX_DEVTOOLS_EXTENSION__()
                : (f) => f
        )
    );

    const persistor = persistStore(store);
    return { store, persistor };
};

const storeAndPersistor = configureStore();

export default storeAndPersistor;
