import { produce } from 'immer';
import { sortBy } from 'lodash';
import { convertToTimeZone } from 'uikit/datePicker/DatePicker';
import * as types from './communicationTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    records: [],
    tableHeaders: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_COMMUNICATIONS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_COMMUNICATIONS.SUCCESS]: produce(
        (draft, { total, communications, timeZone }) => {
            draft.records = communications.map((item) => ({
                ...item,
                createdBy: `${
                    item?.createdBy?.firstName && item?.createdBy?.lastName
                        ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName} `
                        : item?.createdBy?.email
                }`,
                schedule: convertToTimeZone(item?.schedule),
            }));
            draft.totalRecords = total;
            draft.loading = false;
        }
    ),
    [types.GET_COMMUNICATIONS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_COMMUNICATIONS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
