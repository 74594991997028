import { produce } from 'immer';
import * as types from './eventWrapperTypes';
import createReducer from '../../utils/createReducer';

const initialState = {
    themeColors: {
        primaryColor: '#000000',
        secondaryColor: '#000000',
    },
    fontStylesWithFontFamily: {
        h1: 'serif',
        h2: 'serif',
        h3: 'serif',
        h4: 'serif',
        h5: 'serif',
        h6: 'serif',
        h7: 'serif',
    },
    pageId: '',
    eventId: '',
    pageDetail: {
        name: '',
        isShowTitle: true,
    },
    profiles: [],
    sessionSpeakerProfile: '',
    editAttendeeProfile: '',
    timezone: '',
};

const actionHandlers = {
    [types.UPDATE_EVENT_DETAILS]: produce((draft, { eventWrapperData }) => {
        draft.themeColors = eventWrapperData.themeColors;
        draft.fontStylesWithFontFamily =
            eventWrapperData.fontStylesWithFontFamily;
        draft.pageId = eventWrapperData.pageId;
        draft.eventId = eventWrapperData.eventId;
        draft.pageDetail = eventWrapperData.pageDetail;
        draft.profiles = eventWrapperData.profiles;
        draft.sessionSpeakerProfile = eventWrapperData.sessionSpeakerProfile;
        draft.editAttendeeProfile = eventWrapperData.editAttendeeProfile;
        draft.timezone = eventWrapperData?.timezone;
    }),

    [types.RESET_EVENT_DETAILS]: produce((draft) => {
        draft = { ...initialState };
    }),
};

export default createReducer(initialState, actionHandlers);
