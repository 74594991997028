import { produce } from 'immer';
import constants from 'utils/constants';
import { getFormatedDate } from 'utils/common';
import * as types from './organizationTypes';
import createReducer from '../../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_ORGANIZATIONS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_ORGANIZATIONS.SUCCESS]: produce(
        (draft, { total, organizations }) => {
            const records = organizations.map((item) => {
                const userName = item?.updatedBy
                    ? `${item?.updatedBy?.firstName} ${item?.updatedBy?.lastName}`
                    : '';
                return {
                    ...item,
                    status: item?.settings?.status || '',
                    createdAt: getFormatedDate(
                        item.createdAt,
                        constants.GLOBAL.DATE_TIME_FORMATE_FOR_DISPLAY
                    ),
                    updatedAt: getFormatedDate(
                        item.updatedAt,
                        constants.GLOBAL.DATE_TIME_FORMATE_FOR_DISPLAY
                    ),
                    lastModified: `At ${getFormatedDate(
                        item.updatedAt,
                        'hh:mm A'
                    )} on ${getFormatedDate(item.updatedAt, 'MMM DD, YYYY')} ${
                        userName ? `by ${userName}` : ''
                    }`,
                };
            });
            draft.records = records;
            draft.totalRecords = total;
            draft.loading = false;
        }
    ),
    [types.GET_ORGANIZATIONS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_ORGANIZATIONS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
