import { getRoleBasedOnUserDetails } from 'utils/common';
import { HOST, USER_ROLE } from 'utils/constants';
import fetchClient from 'utils/axiosConfig';
import { handleParticipantViewToggle } from 'redux/common/commonActions';
import * as types from './userTypes';
import apiRoutes from '../../constants/apiRoutes';

const loginAction = () => ({
    type: types.LOGIN.REQUEST,
});
const loginSuccess = (token, tokenData) => ({
    type: types.LOGIN.SUCCESS,
    token,
    tokenData,
});
const loginFailure = () => ({
    type: types.LOGIN.FAILURE,
});

export const login =
    (requestData, isAdminLogin = false) =>
    async (dispatch) => {
        try {
            dispatch(loginAction());
            const url = apiRoutes.auth;
            return fetchClient
                .post(url, requestData, { tokenCheck: false })
                .then(({ data: { token, tokenData } }) => {
                    // check if login into cms then redirect user to cms otherwise redirect user to participant view
                    const role = getRoleBasedOnUserDetails(token);
                    // if participant user try to login in cms then we will show error and not allow that user to login in cms
                    if (role === USER_ROLE.PARTICIPANT && isAdminLogin) {
                        return {
                            success: false,
                            message: 'You are not authorized to access cms',
                        };
                    }
                    handleParticipantViewToggle(dispatch, !isAdminLogin);
                    dispatch(loginSuccess(token, tokenData));
                    return { success: true };
                })
                .catch((error) => {
                    dispatch(loginFailure());
                    return {
                        success: false,
                        message: error?.response?.data?.message,
                    };
                });
        } catch ({ message }) {
            dispatch(loginFailure());
            return {
                success: false,
                message: 'An error occurred when logging in.',
            };
        }
    };

export const register = (email, password) => {
    const url = apiRoutes.register;
    return fetchClient
        .post(url, { email, password, host: HOST }, { tokenCheck: false })
        .then(() => ({
            success: true,
        }));
};

export const verifyEmail = (token) => {
    const url = apiRoutes.verifyEmail;
    return fetchClient
        .post(url, { token, host: HOST }, { tokenCheck: false })
        .then(() => ({ success: true }));
};

export const updateUserDetails = (data) => ({
    type: types.UPDATE_USER_DETAILS,
    data,
});

export const updateIsPerformLogoutAction = (isLogoutAction) => ({
    type: types.UPDATE_LOGOUT_PERFORM_STATE,
    isLogoutAction,
});
