import { produce } from 'immer';
import * as types from './dataImportTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_DATA_IMPORTS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_DATA_IMPORTS.SUCCESS]: produce(
        (draft, { total, dataImports }) => {
            draft.records = dataImports;
            draft.totalRecords = total;
            draft.loading = false;
        }
    ),
    [types.GET_DATA_IMPORTS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_DATA_IMPORTS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
