import { produce } from 'immer';
import * as types from './badgeTypes';
import createReducer from '../../utils/createReducer';

const initialState = {
    records: [],
    tableHeaders: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_BADGES.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_BADGES.SUCCESS]: produce((draft, { total, records }) => {
        draft.records = records.map((item) => ({
            ...item,
            gameTasks: item?.gameTasks?.length || 0,
        }));
        draft.totalRecords = total;
        draft.loading = false;
    }),
    [types.GET_BADGES.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_BADGES.SUCCESS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
