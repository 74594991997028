import React from 'react';
import { Box, Dialog } from '@primer/components';
import { Button } from 'uikit/buttons/Button/Button';
import CustomPopup from './CustomPopup/CustomPopup';
import './Popup.scss';

interface Props {
    headerTitle?: string;
    isOpen: boolean;
    handleClose: () => void;
    primaryButtonText?: string;
    primaryButtonOnClick?: () => void;
    secondaryButtonText?: string;
    isPrimaryButtonDisabled?: boolean;
    isHideSecondaryButton?: boolean;
    extraButton?: Element;
    isFullWidth?: boolean;
}

const Popup: React.FC<Props> = (props) => {
    const {
        headerTitle,
        isOpen,
        handleClose,
        primaryButtonText,
        primaryButtonOnClick,
        secondaryButtonText = 'Cancel',
        isPrimaryButtonDisabled,
        children,
        isHideSecondaryButton = false,
        extraButton = <></>,
        isFullWidth = false,
    } = props;
    return (
        <CustomPopup open={isOpen} isFullWidth={isFullWidth}>
            <Box className="popup-contianer">
                {headerTitle && (
                    <Dialog.Header id="header-id">{headerTitle}</Dialog.Header>
                )}
                <Box>{children}</Box>
                {isHideSecondaryButton && !primaryButtonText ? (
                    <></>
                ) : (
                    <Box className="button-container">
                        <Button
                            variant="secondary"
                            buttonClass={`${
                                isHideSecondaryButton ? 'button-hide' : ''
                            }`}
                            onClick={handleClose}
                        >
                            {secondaryButtonText}
                        </Button>
                        <Box display="flex">
                            {extraButton && (
                                <Box display="flex" paddingRight="15px">
                                    {extraButton}{' '}
                                </Box>
                            )}
                            {primaryButtonText && (
                                <Button
                                    variant="primary"
                                    buttonClass={`${
                                        isPrimaryButtonDisabled
                                            ? 'disabled'
                                            : ''
                                    }`}
                                    onClick={primaryButtonOnClick}
                                    disabled={isPrimaryButtonDisabled}
                                    isAllowDisable
                                >
                                    {primaryButtonText}
                                </Button>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </CustomPopup>
    );
};

export default Popup;
