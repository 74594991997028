import { produce } from 'immer';
import * as types from './moderationTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    sessions: [],
    sessionsCount: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_MODERATIONS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_MODERATIONS.SUCCESS]: produce((draft, { total, sessions }) => {
        draft.sessions = sessions;
        draft.sessionsCount = total;
        draft.loading = false;
    }),
    [types.GET_MODERATIONS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_MODERATIONS]: produce((draft) => {
        draft.sessions = [];
        draft.sessionsCount = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
