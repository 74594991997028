import React from 'react';
import { Box } from '@primer/components';
import Popup from 'uikit/popup/Popup';
import Label from 'uikit/labels/Labels/Labels';
import { Loader } from 'uikit/loader/Loader';

// ConfirmationPopup: common confirmation popup
const ConfirmationPopup = ({
    label,
    isOpenPopup,
    togglePopup,
    handlePrimaryAction,
    action = '',
    primaryButtonText = '',
    secondaryButtonText = undefined,
    message = <></>,
    isLoading = false,
    isPrimaryButtonDisabled = false,
    isHideSecondaryButton,
    width = '300px',
}) => (
    <Popup
        headerTitle={action}
        isOpen={isOpenPopup}
        handleClose={togglePopup}
        primaryButtonText={primaryButtonText || action}
        primaryButtonOnClick={handlePrimaryAction}
        secondaryButtonText={secondaryButtonText}
        isPrimaryButtonDisabled={isPrimaryButtonDisabled}
        isHideSecondaryButton={isHideSecondaryButton}
    >
        {isLoading && <Loader />}
        {label ? (
            <Box width={width}>
                <Label
                    variant="label-secondary"
                    label={label}
                    labelClass="p-4 confirmation-label"
                />
            </Box>
        ) : (
            message
        )}
    </Popup>
);

export default ConfirmationPopup;
