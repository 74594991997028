import React, { Suspense, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, useHistory } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { oktaAuth } from 'okta/config';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { login, updateIsPerformLogoutAction } from 'redux/user/userActions';
import { EVENT_REDIRECTION_URL, HOST, eventUrl } from 'utils/constants';
import { ToastContainer } from 'contexts/toast';
import localStorageKeys from 'constants/localStorageKeys';
import { checkMobileApp, validateLoginType } from 'utils/common';
import { Loader } from 'uikit/loader/Loader';
import EventUrlWithRedirectionSupport from 'pages/Participant/EventUrlWithRedirectionSupport/EventUrlWithRedirectionSupport';
import configureStore from './redux/configureStore';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

const App = React.lazy(() => import('./App'));
const MobileApp = React.lazy(() => import('./MobileApp'));

const { store, persistor } = configureStore;
const isRedirectionUrl = window.location.pathname.includes(
    EVENT_REDIRECTION_URL
);

const ReactApp = () => {
    const isMobileApp = checkMobileApp();
    const [toastList, setToastList] = useState([]);
    const { push } = useHistory();
    const dispatch = useDispatch();
    const isPerformLogoutAction = useSelector(
        (state) => state.user.isPerformLogoutAction
    );
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        const isAuthenticated =
            oktaAuth?.authStateManager?.getAuthState()?.isAuthenticated;
        if (!isPerformLogoutAction && isAuthenticated) {
            const token =
                _oktaAuth?.authStateManager?.getAuthState()?.accessToken
                    ?.accessToken;
            if (!token) return;
            const data = {
                sso: 'OKTA',
                token: `${token}`,
                host: HOST,
            };
            const response = await dispatch(login(data));
            if (!response.success) {
                setToastList([
                    {
                        id: new Date().getTime(),
                        content: response.message,
                        variant: 'error',
                    },
                ]);
                setTimeout(() => {
                    handleClose();
                    dispatch(updateIsPerformLogoutAction(true));
                }, 3000);
            }
            // after login redirection logic
            // redirection also handled at login module so if any new change then do check their as well
            const redirectUrl = localStorage.getItem(
                localStorageKeys.REDIRECT_URL
            );
            if (redirectUrl) {
                if (!validateLoginType(eventUrl))
                    localStorage.setItem(localStorageKeys.REDIRECT_URL, '');
                push(toRelativeUrl(redirectUrl, window.location.origin));
            } else {
                push(toRelativeUrl(originalUri || '/', window.location.origin));
            }
        }
    };

    const handleClose = () => setToastList([]);

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            {isMobileApp ? <MobileApp /> : <App />}
            <ToastContainer toasts={toastList} handleClose={handleClose} />
        </Security>
    );
};

ReactDOM.render(
    <Suspense fallback={<Loader />}>
        {isRedirectionUrl ? (
            <EventUrlWithRedirectionSupport />
        ) : (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    {/* ScrictMode commented because Map not working properly with this */}
                    {/*  <React.StrictMode> */}
                    <Router>
                        <ReactApp />
                    </Router>
                    {/* </React.StrictMode> */}
                </PersistGate>
            </Provider>
        )}
    </Suspense>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
