import { produce } from 'immer';
import * as types from './gamificationsTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_GAMIFICATIONS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_GAMIFICATIONS.SUCCESS]: produce((draft, { total, records }) => {
        draft.records = records.map((item) => ({
            ...item,
            createdBy: `${
                item?.createdBy?.firstName && item?.createdBy?.lastName
                    ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName} `
                    : item?.createdBy?.email
            }`,
        }));
        draft.totalRecords = total;
        draft.loading = false;
    }),
    [types.GET_GAMIFICATIONS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_GAMIFICATIONS.SUCCESS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
