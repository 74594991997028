import { produce } from 'immer';
import { sortBy } from 'lodash';
import * as types from './homeTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    events: [],
    totalEvents: 0,
    unfilteredTotal: 0,
    eventsForSidebar: [],
    loading: true,
    selectedEvent: null,
    activeEvents: [],
};

const actionHandlers = {
    [types.GET_EVENTS.REQUEST]: produce((draft) => {
        draft.unfilteredTotal = 0;
        draft.loading = true;
    }),
    [types.GET_EVENTS.SUCCESS]: produce(
        (draft, { total, events, unfilteredTotal }) => {
            draft.events = events;
            draft.totalEvents = total;
            draft.unfilteredTotal = unfilteredTotal;
            draft.loading = false;
        }
    ),
    [types.GET_EVENTS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.UPDATE_EVENTS]: produce((draft, { events }) => {
        draft.events = events;
    }),
    [types.UPDATE_ACTIVE_EVENTS]: produce((draft, { events }) => {
        draft.activeEvents = events;
    }),
    [types.UPDATE_CURRENT_EVENT]: produce((draft, { selectedEvent }) => {
        draft.selectedEvent = selectedEvent;
    }),
    [types.UPDATE_SIDEBAR_EVENTS]: produce((draft, { eventsForSidebar }) => {
        draft.eventsForSidebar = eventsForSidebar;
    }),
    [types.RESET_EVENTS]: produce((draft, { selectedEvent }) => {
        draft.events = [];
        draft.eventsForSidebar = [];
        draft.totalEvents = 0;
        draft.loading = true;
        draft.selectedEvent = null;
        draft.activeEvents = [];
    }),
};

export default createReducer(initialState, actionHandlers);
