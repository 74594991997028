import React, { useState } from 'react';
import ConfirmationPopup from 'components/common/ConfirmationPopup/ConfirmationPopup';
import constants from 'utils/constants';
import { downloadQRCodes } from 'pages/CustomQRCodes/utils';
import { Box } from '@primer/components';
import CustomDropdown from 'uikit/dropdown/CustomDropdown/CustomDropdown';
import { Loader } from 'uikit/loader/Loader';

const useQRCode = ({
    qrCodes,
    qrCodeForDisplay = false,
    fileName,
    resetSelectedRecords,
    size = '100px',
    isDisableDownload = false,
}) => {
    const [isOpenExport, setIsOpenExport] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const qrCodeWidthOptions =
        constants.SCREENS.CUSTOM_QR_CODE.QR_CODE_WIDTH_OPTIONS;
    const [qrCodeWidth, setQRCodeWidth] = useState(qrCodeWidthOptions[0].value);

    const handleQRCodeExport = () => {
        const fName = `${fileName || 'Custom QR Codes'}.zip`;
        downloadQRCodes({
            recordes: qrCodes,
            fileName: fName,
            setIsLoading,
            type: 'pngs',
            width: qrCodeWidth,
        });
        if (resetSelectedRecords) resetSelectedRecords();
    };
    const PreviewQRCode = (
        <>
            {/* Preview single qr code */}
            {qrCodeForDisplay && (
                <Box
                    display="flex"
                    justifyContent="center"
                    id="use-preview-qr-code"
                >
                    <Box
                        style={{
                            cursor: !isDisableDownload ? 'pointer' : 'default',
                        }}
                        onClick={() =>
                            !isDisableDownload && setIsOpenExport(true)
                        }
                    >
                        <img
                            src={qrCodeForDisplay}
                            width={size}
                            height={size}
                            alt="qr"
                        />
                    </Box>
                </Box>
            )}
            <ConfirmationPopup
                action="Export"
                isOpenPopup={isOpenExport}
                togglePopup={() => setIsOpenExport('')}
                isPrimaryButtonDisabled={!qrCodeWidth}
                handlePrimaryAction={() => {
                    setIsLoading(true);
                    setTimeout(() => {
                        handleQRCodeExport();
                        setIsOpenExport(false);
                    }, 200);
                }}
                message={
                    <Box padding="30px">
                        {isLoading && <Loader />}
                        <CustomDropdown
                            label="QR Code Size"
                            isSingleSelect
                            isSingleArrow
                            selected={qrCodeWidthOptions.filter(
                                (item) => item.value === qrCodeWidth
                            )}
                            options={qrCodeWidthOptions}
                            setSelected={(selectedItems) => {
                                setQRCodeWidth(selectedItems[0].value);
                            }}
                            dropdownWidth="300px"
                        />
                    </Box>
                }
            />
        </>
    );

    return { setIsOpenExport, PreviewQRCode };
};

export default useQRCode;
