import React, { ReactNode, useState } from 'react';
import Select, { components, DropdownIndicatorProps } from 'react-select';
import { Box } from '@primer/components';
import Label from 'uikit/labels/Labels/Labels';
import LabelWithEllipsis from 'uikit/labels/LabelWithEllipsis/LabelWithEllipsis';
import constants from 'utils/constants';
import CustomOption, { DropdownOptionItem } from './CustomOption/CustomOption';
import DropdownIndicator from './CustomDropdownIndicator/CustomDropdownIndicator';
import CustomValueControl from './CustomValueControl/CustomValueControl';
import './CustomDropdown.scss';
// https://www.saltycrane.com/cheat-sheets/typescript/react-select/latest/#react-select

interface CustomDropdownPropType {
    label?: string;
    isSingleSelect?: boolean;
    options: DropdownOptionItem[];
    setSelected: (arg0: DropdownOptionItem[]) => void;
    selected: DropdownOptionItem[];
    isSingleArrow?: boolean;
    dropdownWidth?: number;
    alreadyUsedOption?: DropdownOptionItem[];
    isCopyIconVisible?: boolean;
    isShowErrorMessage?: boolean;
    errorMessage?: string;
    isValueUpdated?: boolean;
    isSearchable?: boolean;
    menuShouldBlockScroll?: boolean;
    tooltipLabel?: string | ReactNode;
    labelClass?: string;
    readOnly?: boolean;
    onSearchInputChange?: (input: string) => void;
    isSearchOutSide?: boolean;
    placeholder?: string;
    className?: string;
    labelInfoText?: string;
    isRequired?: boolean;
    handleActionClick?: () => void | undefined;
    icon?: string | ReactNode;
    isClearable?: boolean;
    CustomIcon?: string | ReactNode;
    onCustomIconClick?: () => void | undefined;
}

const CustomDropdown: React.FC<CustomDropdownPropType> = (props) => {
    const {
        label,
        isSingleSelect = true,
        isSingleArrow = false,
        isClearable = false,
        options,
        selected,
        setSelected,
        dropdownWidth = 262,
        alreadyUsedOption = [],
        isCopyIconVisible = false,
        isShowErrorMessage = false,
        errorMessage = '',
        isValueUpdated = false,
        isSearchable = false,
        menuShouldBlockScroll = false,
        tooltipLabel = '',
        labelClass = 'with-padding',
        readOnly = false,
        onSearchInputChange,
        isSearchOutSide = false,
        placeholder = 'Select...',
        className = '',
        labelInfoText = '',
        isRequired = false,
        handleActionClick,
        icon = '',
        CustomIcon,
        onCustomIconClick,
    } = props;

    const [isFocused, setIsFocused] = useState(false);
    const handleSelectedValue = (newValue: unknown) => {
        if (isSingleSelect) {
            setSelected([{ ...(newValue as DropdownOptionItem) }]);
        } else {
            setSelected([...(newValue as DropdownOptionItem[])]);
        }
    };

    // array of available custom filter options which is used to prevent search on filter
    const customFilterOptions = [
        constants.COMPONENTS.DROPDOWN.IGNORE_ITEM,
        constants.COMPONENTS.DROPDOWN.IGNORE_COLUMN,
        constants.COMPONENTS.DROPDOWN.CREATE_NEW_FIELD,
        constants.COMPONENTS.DROPDOWN.ADD_NEW_VALUE,
    ];

    // filterOptions: function to filter dropdown options using search box
    const filterOptions = (option: unknown, filter: string) => {
        if (!filter || isSearchOutSide) {
            return true;
        }

        const { label: optionLabel } = option as DropdownOptionItem;

        if (
            (optionLabel &&
                optionLabel?.toLowerCase().includes(filter?.toLowerCase())) ||
            customFilterOptions.includes(optionLabel)
        ) {
            return true;
        }
        return false;
    };

    const componentsState = {
        Option: CustomOption,
        DropdownIndicator: (dropdownProps: DropdownIndicatorProps) => (
            <DropdownIndicator
                isSingleArrow={isSingleArrow}
                {...dropdownProps}
            />
        ),
        IndicatorSeparator: () => null,
        Control: CustomValueControl,
        MultiValue: components.MultiValue,
    };

    let placeholderLabel = placeholder;
    if (selected.length > 0) {
        // for multi select
        if (!isSingleSelect) {
            placeholderLabel = `${selected.length} ${
                selected.length === 1 ? 'item selected' : 'items selected'
            }`;
        } else if (selected[0]?.label) {
            // for single select
            placeholderLabel = `${selected[0].label}`;
        }
    }

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    return (
        <Box>
            <Box id="dataImportDropdown" width={dropdownWidth}>
                {label && (
                    <Box display="flex" justifyContent="space-between">
                        <Label
                            variant={
                                !readOnly ? 'normal' : 'covision-input-label'
                            }
                            labelClass={labelClass}
                            label={label}
                            infoText={labelInfoText}
                        />
                        <Box display="flex">
                            {isRequired && !readOnly && (
                                <Label
                                    label="Required"
                                    variant="outline"
                                    labelClass="required-label"
                                />
                            )}
                            {icon && (
                                <Box
                                    paddingLeft="5px"
                                    onClick={handleActionClick}
                                    className="icon-image"
                                >
                                    {icon}
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
                {readOnly ? (
                    <Box>
                        {isSingleSelect ? (
                            <Label
                                label={selected
                                    .map((item) => item.label)
                                    .join(', ')}
                                variant="normal"
                                labelClass="with-padding"
                            />
                        ) : (
                            <LabelWithEllipsis
                                value={selected
                                    .map((item) => item.label)
                                    .join(', ')}
                                width={dropdownWidth}
                                className="covision-input-label"
                            />
                        )}
                    </Box>
                ) : (
                    <Box
                        title={
                            isSingleSelect && placeholder !== placeholderLabel
                                ? placeholderLabel
                                : ''
                        }
                    >
                        <Select
                            classNamePrefix="custom-dropdown"
                            className={`${className} ${
                                isShowErrorMessage ? 'error' : ''
                            } ${isSearchable ? 'search-icon' : ''} ${
                                isValueUpdated ? 'updated' : ''
                            }`}
                            menuPortalTarget={document.body}
                            menuShouldBlockScroll={menuShouldBlockScroll}
                            options={options}
                            components={componentsState}
                            isSearchable={isSearchable}
                            value={selected}
                            onChange={handleSelectedValue}
                            closeMenuOnSelect={isSingleSelect}
                            isClearable={isClearable}
                            backspaceRemovesValue={false}
                            controlShouldRenderValue={false} // we are showing value as placeholder so not showing actual value
                            // to pass custom props inside this react-select component we have to disabled ts for props
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            alreadyUsedOption={alreadyUsedOption}
                            menuPlacement="auto"
                            menuPosition="fixed"
                            maxMenuHeight={200}
                            filterOption={filterOptions}
                            tooltipLabel={tooltipLabel}
                            isCopyIconVisible={isCopyIconVisible}
                            CustomIcon={CustomIcon}
                            onCustomIconClick={onCustomIconClick}
                            isMulti={!isSingleSelect}
                            hideSelectedOptions={false}
                            onInputChange={onSearchInputChange}
                            noOptionsMessage={() =>
                                isSearchOutSide ? null : 'No options'
                            }
                            placeholder={
                                isSearchable && isFocused
                                    ? ''
                                    : placeholderLabel
                            }
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            blurInputOnSelect={!!isSingleSelect}
                        />
                    </Box>
                )}
                {isShowErrorMessage && errorMessage ? (
                    <Label label={errorMessage} variant="error" />
                ) : (
                    <></>
                )}
            </Box>
        </Box>
    );
};

export default CustomDropdown;
