import { produce } from 'immer';
import { formatTimeSlot } from 'components/sessions/sessionsTableDefinition';
import { convertToTimeZone } from 'uikit/datePicker/DatePicker';
import * as types from './intervalsTypes';
import createReducer from '../../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_MEETINGS_INTERVALS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_MEETINGS_INTERVALS.SUCCESS]: produce(
        (draft, { total, meetingsIntervals }) => {
            draft.records = meetingsIntervals.map((interval) => ({
                ...interval,
                userName:
                    interval.firstName && interval.lastName
                        ? `${interval.firstName} ${interval.lastName}`
                        : interval.email,
                intervals: interval.intervals.map((i) => ({
                    ...i,
                    timeSloat: formatTimeSlot({
                        startAt: convertToTimeZone(i.startAt),
                        endAt: convertToTimeZone(i.endAt),
                    }),
                })),
            }));
            draft.totalRecords = total;
            draft.loading = false;
        }
    ),
    [types.GET_MEETINGS_INTERVALS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_MEETINGS_INTERVALS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
