import localStorageKeys from 'constants/localStorageKeys';
import React, { useEffect, useState } from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { Loader } from 'uikit/loader/Loader';
import useQRCode from 'uikit/hooks/useQRCode/useQRCode';
import { Box } from '@primer/components';
import { getQRCode } from 'pages/CustomQRCodes/utils';
import logoImage from 'components/sideNav/assets/logo.png';
import { Button } from 'uikit/buttons/Button/Button';
import './EventUrlWithRedirectionSupport.scss';
import { EVENT_REDIRECTION_URL, EVENT_URL } from 'utils/constants';
import { getPlayStoreOrAppStoreUrl } from 'utils/common';

const EventUrlWithRedirectionSupport = () => {
    const urlDetails = window.location.href.split('url=');
    const redirectedUrl =
        urlDetails.length === 2
            ? urlDetails[1]
            : urlDetails[0].replace(EVENT_REDIRECTION_URL, '');
    const [isShowDownloadAppSection, setIsShowDownloadAppSection] =
        useState(false);
    const [qrCode, setQRCode] = useState('');
    const url = window.location.href;
    const { PreviewQRCode } = useQRCode({
        qrCodes: [
            {
                value: url,
                title: 'qr code',
            },
        ],
        qrCodeForDisplay: qrCode,
        size: '250px',
        isDisableDownload: true,
    });

    useEffect(() => {
        getQRCode(url, setQRCode);
    }, [url]);

    const redirectToStore = () => {
        const { playStoreOrAppStoreUrl } = getPlayStoreOrAppStoreUrl();
        window.location.href = playStoreOrAppStoreUrl;
    };
    useEffect(() => {
        //  event-url link we redirect user to a screen which is public : done
        // check if link opens in Android / iOS browser we will check app is installed or not
        if (isMobile) {
            const linkDetails = redirectedUrl.split(`/${EVENT_URL}/`);
            const eventId = linkDetails.length > 1 ? linkDetails[1] : '';
            // if installed we will redirect user to mobile app directly using deeplink
            // **Replace with your app's deep linking URL schemes**
            // covision://development / mckinsey / events.covision.com /hub/type/value
            // const environment = ['demo', 'staging'].includes(ENVIRONMENT)
            //     ? 'development'
            //     : ENVIRONMENT;

            // const appLink = `${environment}://${`${environment}.covision.com`}/${hostname.replace(
            //     '.covision.com',
            //     ''
            // )}/${ENVIRONMENT}/event-redirection/${eventId}`;
            // user scans qr code to download app without any url then we will redirect user to playstore / appstore
            // if (isMobile && !eventId) {
            //     redirectToStore();
            //     return;
            // }
            // const androidOriOSLink = document.createElement('a');
            // androidOriOSLink.href = appLink;

            // try {
            //     if (androidOriOSLink && androidOriOSLink.click) {
            //         androidOriOSLink.click(); // Attempt to open Android app
            //     }
            // } catch (error) {
            //     redirectToStore();
            // } finally {
            //     androidOriOSLink.remove(); // Remove temporary elements
            // }
            // Use a timeout to check if the app was opened or not
            // const timeout = setTimeout(() => {
            // If the app was not opened, this will execute and redirect the user to the store
            redirectToStore();
            // }, 7000); // Adjust the timeout as needed
            // Prevent the redirection if the app is opened
            // window.onblur = () => {
            // clearTimeout(timeout);
            // };
        } else {
            // show qr code to download android / ios app
            // QR code will contain same link which will open this same screen in mobile browser
            setIsShowDownloadAppSection(true);
        }
    }, []);

    const hanldeContinueOnWeb = () => {
        window.location.href = redirectedUrl;
    };
    return (
        <Box id="event-url-with-redirection">
            {!isShowDownloadAppSection ? (
                <Loader />
            ) : (
                <Box>
                    {isDesktop && (
                        <Box padding="5px">
                            <img src={logoImage} alt="Covision" />
                        </Box>
                    )}
                    <Box
                        display="flex"
                        justifyContent="center"
                        height={isMobile ? '500px' : ''}
                        width="80%"
                        margin="auto"
                        paddingTop="20px"
                    >
                        <Box display="flex" width="100%" flexWrap="wrap">
                            {isDesktop && (
                                <>
                                    <Box
                                        display="flex"
                                        flex="1"
                                        justifyContent="center"
                                    >
                                        <img
                                            src="/images/covision-app.png"
                                            alt="Covision"
                                            height="500px"
                                        />
                                    </Box>
                                </>
                            )}
                            <Box
                                flex="1"
                                display="flex"
                                justifyContent="center"
                            >
                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    flexDirection="column"
                                >
                                    {isDesktop ? (
                                        <>
                                            <Box>{qrCode && PreviewQRCode}</Box>
                                            <Box as="h3" textAlign="center">
                                                {urlDetails.length === 2
                                                    ? 'Continue On App'
                                                    : 'Download Our App'}
                                            </Box>
                                            <Box as="h4" textAlign="center">
                                                Or
                                            </Box>
                                            <Box textAlign="center">
                                                <Button
                                                    onClick={
                                                        hanldeContinueOnWeb
                                                    }
                                                    variant="primary"
                                                >
                                                    Continue On Web
                                                </Button>
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Loader />
                                            {/* <Box paddingBottom="10px">
                                            <Box paddingBottom="20px">
                                                <img
                                                    src="/images/covision-app.png"
                                                    alt="Covision"
                                                    height="300px"
                                                />
                                            </Box>
                                            <Box
                                                onClick={() => {
                                                    window.location.href =
                                                        getPlayStoreOrAppStoreUrl();
                                                }}
                                            >
                                                <img
                                                    src={
                                                        isAndroid
                                                            ? '/images/play store icon.svg'
                                                            : '/images/app store icon.svg'
                                                    }
                                                    alt="Covision"
                                                    height="50px"
                                                />
                                            </Box>
                                        </Box> */}
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default EventUrlWithRedirectionSupport;
