import { produce } from 'immer';
import * as types from './customQRCodeTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    customQRCodes: [],
    customQRCodesCount: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_CUSTOM_QR_CODES.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_CUSTOM_QR_CODES.SUCCESS]: produce(
        (draft, { total, customQRCodes }) => {
            draft.customQRCodes = customQRCodes;
            draft.customQRCodesCount = total;
            draft.loading = false;
        }
    ),
    [types.GET_CUSTOM_QR_CODES.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_CUSTOM_QR_CODES]: produce((draft) => {
        draft.customQRCodes = [];
        draft.customQRCodesCount = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
