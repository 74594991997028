import { produce } from 'immer';
import { getEventDateAndTime } from 'utils/common';
import * as types from './eventTypes';
import createReducer from '../../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_EVENT.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_EVENT.SUCCESS]: produce((draft, { total, events }) => {
        const records = events.map((item) => ({
            ...item,
            organizationId: item?.organization?.name,
            eventHubId: item?.eventHub?.name,
            createdAt: getEventDateAndTime(item),
            startAt: item.startAt,
            endAt: item.endAt,
        }));
        draft.records = records;
        draft.totalRecords = total;
        draft.loading = false;
    }),
    [types.GET_EVENT.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_EVENT]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
