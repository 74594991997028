import React, { useState } from 'react';
import { OptionProps, Props } from 'react-select';
import Copy from 'clipboard-copy';
import PlusIcon from 'images/plus.svg';
import CopyIcon from 'images/copytoclipbord.png';
import constants from 'utils/constants';
import './CustomOption.scss';
import { Box } from '@primer/components';
import { useToast } from 'contexts/toast';
import { Checkbox } from 'uikit/forms/Checkbox/Checkbox';

export type CustomOptionPropType = Props & {
    alreadyUsedOption?: DropdownOptionItem[];
    isCopyIconVisible?: boolean;
    isMulti?: boolean;
    CustomIcon?: string;
    onCustomIconClick?: (arg: string) => void;
};

export type enumItemsType = {
    value: string;
    label: string;
}[];

export type DropdownOptionItem = {
    value: string;
    label: string;
    data?: {
        fileTypesAllowed?: string[]; // for file upload
        tableName?: string; // for data import
        showCreateCustomField?: boolean; // to show add new custom field
        dataType?: string; // to store data type of add new custom field
        isMandatory?: boolean; // this field is mandatory or not
        isCustomField?: boolean; // this field is custom field or not
        enumItems?: enumItemsType; // for enum data type field
        isMultiSelect?: boolean;
        isShowEditable?: boolean;
        exportFileName?: string; // for export template name
        isEnumMultiple?: boolean;
    };
};

const CustomOption: React.FC<OptionProps> = (customOptionProps) => {
    const { innerProps, data, isSelected, getValue, selectProps } =
        customOptionProps;

    const { showToast } = useToast();

    const [isShowCopyText, setIsShowCopyText] = useState(false);

    const {
        alreadyUsedOption = [],
        isCopyIconVisible = false,
        isMulti = false,
        CustomIcon,
        onCustomIconClick,
    } = selectProps as CustomOptionPropType;

    const selected = getValue() as DropdownOptionItem[];

    const { label, value } = data as DropdownOptionItem;

    if (
        label === constants.COMPONENTS.DROPDOWN.IGNORE_COLUMN ||
        label === constants.COMPONENTS.DROPDOWN.IGNORE_ITEM
    )
        return (
            <div
                role="button"
                aria-label=""
                onKeyPress={() => undefined}
                tabIndex={0}
                className="custom-field-container ignore-field"
                onClick={innerProps.onClick}
            >
                {label}
            </div>
        );

    if (value === constants.COMPONENTS.DROPDOWN.CREATE_NEW_FIELD)
        return (
            <div
                className="custom-field-container create-new-field"
                role="button"
                aria-label=""
                onKeyPress={() => undefined}
                tabIndex={0}
                onClick={innerProps.onClick}
            >
                {' '}
                <img src={PlusIcon} alt="+" /> {label}
            </div>
        );

    if (label === constants.COMPONENTS.DROPDOWN.ADD_NEW_VALUE)
        return (
            <div
                className="custom-field-container create-new-field"
                role="button"
                aria-label=""
                onKeyPress={() => undefined}
                tabIndex={0}
                onClick={innerProps.onClick}
            >
                {' '}
                Add new value {`"${value}"`}
            </div>
        );

    let isValueAlredySelectedInOtherDropdown = false;

    if (alreadyUsedOption.length > 0) {
        isValueAlredySelectedInOtherDropdown = !!alreadyUsedOption.find(
            (item) => {
                if (selected && selected.length > 0 && selected[0]) {
                    return item.value === value && selected[0].value !== value;
                }
                return item.value === value;
            }
        );
    }

    const handleCopyText = async () => {
        /* Copy the selected text */
        await Copy(label);
        showToast(`Copied: ${label}`, 'success');
    };

    return (
        <div
            role="button"
            aria-label=""
            onKeyPress={() => undefined}
            tabIndex={0}
            id="dropdown-item"
            className={`${
                !isMulti && (isSelected || isValueAlredySelectedInOtherDropdown)
                    ? 'disabled'
                    : ''
            }`}
            onMouseEnter={() => setIsShowCopyText(true)}
            onMouseLeave={() => setIsShowCopyText(false)}
        >
            <Box display="flex" alignItems="center">
                <Box
                    className="normal-field"
                    onClick={
                        (!isSelected &&
                            !isValueAlredySelectedInOtherDropdown) ||
                        isMulti
                            ? innerProps.onClick
                            : () => null
                    }
                >
                    {/* Checkbox */}
                    <Box className="label-container">
                        {isMulti && (
                            <Checkbox
                                className="dropdown-checkbox"
                                checked={isSelected}
                            />
                        )}
                        <span>{label}</span>
                    </Box>
                </Box>
                {isShowCopyText && isCopyIconVisible && (
                    <Box
                        position="absolute"
                        right="0"
                        onClick={handleCopyText}
                        className="copy-icon"
                    >
                        <img src={CopyIcon} alt="C" height="20" width="20" />
                    </Box>
                )}
                {onCustomIconClick && isShowCopyText && CustomIcon && (
                    <Box
                        position="absolute"
                        right="2"
                        onClick={() => onCustomIconClick(value)}
                        className="copy-icon"
                    >
                        <img
                            src={CustomIcon}
                            alt="C"
                            height="15x"
                            width="15x"
                        />
                    </Box>
                )}
            </Box>
        </div>
    );
};

export default CustomOption;
