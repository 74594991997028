import React, { MouseEvent, useState } from 'react';
import './Button.scss';

/**
 * Button: To show button component
 *
 * Example :
 *
 * <Button
      buttonClass="button-space" // we can add custom class on button
      variant={primaryButtonVariant} // any of the variant from below variants
      onClick={primaryButtonOnClick} // function which can be call on this button click
    >
      {primaryButtonText}
    </Button>
 *  variants:- "primary"| "secondary"| "danger"| "disabled"| "large"| "medium"| "small"| "dropdown";
 */

interface Props {
    variant?:
        | 'primary'
        | 'secondary'
        | 'danger'
        | 'dark'
        | 'disabled'
        | 'large'
        | 'medium'
        | 'small'
        | 'success'
        | 'dropdown'
        | 'link';
    onClick?: (e: MouseEvent) => void;
    buttonClass?: string;
    disabled?: boolean;
    isAllowDisable?: boolean;
    isUsePropagation?: boolean;
}

export const Button: React.FC<Props> = (props) => {
    const {
        variant = 'primary',
        disabled = false,
        buttonClass,
        onClick,
        children,
        isAllowDisable = false,
        isUsePropagation = true,
        ...rest
    } = props;

    const [isDisable, setIsDisable] = useState(false);

    return (
        <button
            className={`button ${variant} ${buttonClass ?? ''}`}
            disabled={disabled || (isAllowDisable && isDisable)}
            type="button"
            {...rest}
            onClick={(e) => {
                if (isUsePropagation) e.stopPropagation();
                if (onClick) {
                    if (isAllowDisable) {
                        setIsDisable(true);
                        setTimeout(() => {
                            setIsDisable(false);
                        }, 400);
                    }
                    onClick(e);
                }
            }}
        >
            <span>{children}</span>
        </button>
    );
};
