import React, { ReactNode } from 'react';
import { Box } from '@primer/components';
import OverlayTooltip, {
    TooltipPlacement,
} from 'uikit/tooltips/OverlayToolTip/OverlayTooltip';

/**
 * LabelWithEllipsis:
 * Component to show any label with ellipsis
 */

interface Props {
    value: string | boolean | undefined;
    width: number | string;
    isNormalTooltip?: boolean;
    customTooltipValue?: string;
    className?: 'covision-input-label' | '' | string;
    icon?: ReactNode;
    style?: any;
    placement?: TooltipPlacement;
}

const LabelWithEllipsis: React.FC<Props> = ({
    value,
    width,
    isNormalTooltip = true,
    customTooltipValue = '',
    className = '',
    icon = <></>,
    style = {},
    placement = 'bottom',
}) => {
    const newValue = value === undefined ? '' : `${value as string}`;
    return (
        <OverlayTooltip
            placement={placement}
            label={isNormalTooltip ? newValue : customTooltipValue}
        >
            <Box
                className={`ellipsis ${className}`}
                maxWidth={width}
                style={style}
            >
                {newValue} {icon}
            </Box>
        </OverlayTooltip>
    );
};

export default LabelWithEllipsis;
