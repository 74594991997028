import { produce } from 'immer';
import * as types from './participantTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    participants: [],
    participantsCount: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_PARTICIPANTS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_PARTICIPANTS.SUCCESS]: produce(
        (draft, { total, participants }) => {
            draft.participants = participants;
            draft.participantsCount = total;
            draft.loading = false;
        }
    ),
    [types.GET_PARTICIPANTS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_PARTICIPANTS]: produce((draft) => {
        draft.participants = [];
        draft.participantsCount = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
