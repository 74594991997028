import React from 'react';
import { getFormatedDate, getTextFromHtmlToShow } from 'utils/common';

export const formatDate = (dateString) =>
    dateString
        ? new Date(dateString).toLocaleString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
          })
        : null;

export const formatTimeSlot = (dateString) => {
    const startTime = dateString?.startAt
        ? getFormatedDate(dateString.startAt, 'hh:mm A')
        : '';
    const endTime = dateString?.endAt
        ? getFormatedDate(dateString.endAt, 'hh:mm A')
        : '';
    if (startTime && endTime) return `${startTime} - ${endTime}`;
    return `${startTime || endTime}`;
};

export const columns = [
    {
        field: 'name',
        headerName: 'Name',
        sortable: true,
    },
    {
        field: 'description',
        headerName: 'Description',
        cellRenderer: ({ value }) => getTextFromHtmlToShow(value),
        sortable: true,
    },
    {
        field: 'speakers',
        headerName: 'Speakers',
        cellRenderer: 'LabelWithEllipsisInTable',
        sortable: false,
    },
    {
        field: 'startAt',
        headerName: 'Date',
        cellRenderer: ({ value }) => formatDate(value),
        sortable: true,
    },
    {
        field: 'collections',
        headerName: 'Collection',
        cellRenderer: 'CollectionsWithColorBG',
        sortable: false,
    },
    {
        field: 'status',
        headerName: 'Status',
        sortable: true,
    },
    {
        field: 'accessControl',
        headerName: 'Access Control',
        sortable: true,
    },
    {
        field: 'timeSlot',
        headerName: 'Time Slot',
        sortable: false,
    },
    {
        field: 'location',
        headerName: 'Location',
        cellRenderer: ({ value }) => `${value?.name || ''}`,
        sortable: false,
    },
    {
        field: 'virtualLink',
        headerName: 'Virtual Link',
        sortable: true,
    },
    {
        field: 'moderators',
        headerName: 'Moderators',
        cellRenderer: 'LabelWithEllipsisInTable',
        sortable: false,
    },
    {
        field: 'audienceCount',
        headerName: 'Attendance',
        cellRenderer: ({ value }) =>
            value
                ? value > 1
                    ? `${value} attendees`
                    : `${value} attendee`
                : 'No attendees',
        sortable: false,
    },
    {
        field: 'audience',
        headerName: 'Audience',
        cellRenderer: 'LabelWithColorBG',
        sortable: false,
    },
    {
        field: 'breakout',
        headerName: 'Breakout',
        cellRenderer: ({ value }) => value?.name || '',
        sortable: false,
    },
    {
        field: 'capacity',
        headerName: 'Capacity',
        sortable: true,
    },
    {
        field: 'materials',
        headerName: 'Materials',
        cellRenderer: 'LabelWithEllipsisInTable',
        sortable: false,
    },
    {
        field: 'instances',
        headerName: 'Engagement Tools',
        cellRenderer: 'LabelWithEllipsisInTable',
        sortable: false,
    },
    {
        field: 'enrollable',
        headerName: 'Enrollable',
        cellRenderer: 'LabelWithInfoInTable',
        sortable: false,
    },
];
