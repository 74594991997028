import { produce } from 'immer';
import * as types from './integrationPastHistoryTypes';
import createReducer from '../../utils/createReducer';

const initialState = {
    records: [],
    recordsCount: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_INTEGRATION_PAST_HISTORY.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_INTEGRATION_PAST_HISTORY.SUCCESS]: produce(
        (draft, { total, records }) => {
            draft.records = records;
            draft.recordsCount = total;
            draft.loading = false;
        }
    ),
    [types.GET_INTEGRATION_PAST_HISTORY.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_INTEGRATION_PAST_HISTORY]: produce((draft) => {
        draft.records = [];
        draft.recordsCount = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
