import { produce } from 'immer';
import constants from 'utils/constants';
import { getFormatedDate } from 'utils/common';
import * as types from './reportTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_REPORTS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_REPORTS.SUCCESS]: produce((draft, { total, records }) => {
        draft.records = records;
        draft.totalRecords = total;
        draft.loading = false;
    }),
    [types.GET_REPORTS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_REPORTS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
