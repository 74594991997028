import { produce } from 'immer';
import localStorageKeys from 'constants/localStorageKeys';
import { getRoleBasedOnUserDetails } from 'utils/common';
import * as types from './userTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    userBasicDetails: {
        firstName: '',
        lastName: '',
        photoURL: '',
    },
    authenticated: !!localStorage.getItem(localStorageKeys.ACCESS_TOKEN),
    tokenData: undefined,
    userData: localStorage.getItem(localStorageKeys.USER_DATA)
        ? JSON.parse(localStorage.getItem(localStorageKeys.USER_DATA))
        : '',
    isPerformLogoutAction: false,
};

const actionHandlers = {
    [types.LOGIN.REQUEST]: produce((draft) => {
        localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
        localStorage.removeItem(localStorageKeys.ROLE);
        localStorage.removeItem(localStorageKeys.USERID);
        localStorage.removeItem(localStorageKeys.USER_DATA);
        localStorage.removeItem(localStorageKeys.PARTICIPANT_VIEW);
        draft.authenticated = false;
        draft.tokenData = undefined;
        draft.userData = undefined;
    }),
    [types.LOGIN.SUCCESS]: produce((draft, { token, tokenData }) => {
        localStorage.setItem(localStorageKeys.ACCESS_TOKEN, token);
        const role = getRoleBasedOnUserDetails(token);
        localStorage.setItem(localStorageKeys.ROLE, role);
        localStorage.setItem(localStorageKeys.USERID, tokenData.userId);
        const data = {
            eventHubId: tokenData.eventHubId,
        };
        localStorage.setItem(localStorageKeys.USER_DATA, JSON.stringify(data));
        draft.authenticated = true;
        draft.tokenData = tokenData;
        draft.userData = data;
    }),
    [types.LOGIN.FAILURE]: produce((draft) => {
        localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
        localStorage.removeItem(localStorageKeys.ROLE);
        localStorage.removeItem(localStorageKeys.USERID);
        localStorage.removeItem(localStorageKeys.USER_DATA);
        localStorage.removeItem(localStorageKeys.PARTICIPANT_VIEW);
        draft.authenticated = false;
        draft.tokenData = undefined;
        draft.userData = undefined;
    }),
    [types.LOGOUT]: produce((draft) => {
        localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
        localStorage.removeItem(localStorageKeys.ROLE);
        localStorage.removeItem(localStorageKeys.USERID);
        localStorage.removeItem(localStorageKeys.USER_DATA);
        localStorage.removeItem(localStorageKeys.PARTICIPANT_VIEW);
        draft.authenticated = false;
        draft.tokenData = undefined;
        draft.userData = undefined;
        draft.userBasicDetails = undefined;
    }),
    [types.UPDATE_USER_DETAILS]: produce((draft, { data }) => {
        draft.userBasicDetails = data;
    }),
    [types.UPDATE_LOGOUT_PERFORM_STATE]: produce(
        (draft, { isLogoutAction }) => {
            draft.isPerformLogoutAction = isLogoutAction;
        }
    ),
};

export default createReducer(initialState, actionHandlers);
