import { produce } from 'immer';
import * as types from './notificationMessagesTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_NOTIFICATION_MESSAGES.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_NOTIFICATION_MESSAGES.SUCCESS]: produce(
        (draft, { total, communications }) => {
            draft.records = communications.map((item) => ({
                ...item,
                createdBy: `${
                    item?.createdBy?.firstName && item?.createdBy?.lastName
                        ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName} `
                        : item?.createdBy?.email
                }`,
            }));
            draft.totalRecords = total;
            draft.loading = false;
        }
    ),
    [types.GET_NOTIFICATION_MESSAGES.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_NOTIFICATION_MESSAGES]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
