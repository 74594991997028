import { produce } from 'immer';
import localStorageKeys from 'constants/localStorageKeys';
import * as types from './commonTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    isParticipantView:
        localStorage?.getItem(localStorageKeys.PARTICIPANT_VIEW) === 'true',
    dataTagsWithValues: [],
    mainDataTags: [],
    dataTagsOptions: [],
};

const actionHandlers = {
    [types.IS_PARTICIPANT_VIEW]: produce((draft, { isParticipantView }) => {
        draft.isParticipantView = isParticipantView;
    }),
    [types.DATA_TAGS_VALUES]: produce((draft, { dataTagsWithValues }) => {
        draft.dataTagsWithValues = dataTagsWithValues;
    }),
    [types.DATA_TAGS_OPTIONS]: produce((draft, { dataTagsOptions }) => {
        draft.dataTagsOptions = dataTagsOptions;
    }),
    [types.MAIN_DATA_TAGS_OPTIONS]: produce((draft, { mainDataTags }) => {
        draft.mainDataTags = mainDataTags;
    }),
};

export default createReducer(initialState, actionHandlers);
