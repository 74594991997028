import { produce } from 'immer';
import constants from 'utils/constants';
import { getFormatedDate } from 'utils/common';
import * as types from './materialTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_MATERIALS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_MATERIALS.SUCCESS]: produce((draft, { total, materials }) => {
        const records = materials.map((item) => {
            const userName = item?.updatedBy
                ? `${item?.updatedBy?.firstName} ${item?.updatedBy?.lastName}`
                : '';
            return {
                ...item,
                createdAt: getFormatedDate(
                    item.createdAt,
                    constants.GLOBAL.DATE_TIME_FORMATE_FOR_DISPLAY
                ),
                updatedAt: getFormatedDate(
                    item.updatedAt,
                    constants.GLOBAL.DATE_TIME_FORMATE_FOR_DISPLAY
                ),
                username: userName,
                lastModified: `At ${getFormatedDate(
                    item.updatedAt,
                    'hh:mm A'
                )} on ${getFormatedDate(item.updatedAt, 'MMM DD, YYYY')} ${
                    userName ? `by ${userName}` : ''
                }`,
            };
        });
        draft.records = records;
        draft.totalRecords = total;
        draft.loading = false;
    }),
    [types.GET_MATERIALS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_MATERIALS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
