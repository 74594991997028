import React from 'react';
import DatePickerComponent from 'react-datepicker';
import { Box } from '@primer/components';
import Label from 'uikit/labels/Labels/Labels';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
// https://reactdatepicker.com/

export const getOnlyDateValue = (date, dateValueFormat) =>
    moment
        .utc(
            `${
                !dateValueFormat
                    ? moment(date).format('YYYY-MM-DD')
                    : moment(date, dateValueFormat).format('YYYY-MM-DD')
            }T00:00:00.000Z`
        )
        .startOf('day')
        .format();

export const convertToLocalTime = (date) => {
    const dateFormat = new Date(date);
    const userTimezoneOffset = dateFormat.getTimezoneOffset() * 60000;
    const dateStr = new Date(dateFormat.getTime() - userTimezoneOffset);
    return dateStr.toISOString();
};

export const convertToTimeZone = (date) => {
    const dateFormat = new Date(date);
    const userTimezoneOffset = dateFormat.getTimezoneOffset() * 60000;
    const dateStr = new Date(dateFormat.getTime() + userTimezoneOffset);
    return dateStr;
};

const DatePicker = (props) => {
    const {
        className = '',
        label,
        isShowErrorMessage = false,
        errorMessage = '',
        isRequired = false,
        labelClass = 'with-padding',
        readOnly = false,
        isDateOnly = false,
        selected,
        onChange,
        dateFormat = 'Pp',
        placeholderText,
        value,
        showTimeSelectOnly = false,
        ...rest
    } = props;

    const setLocalZone = (date) =>
        !isDateOnly ? convertToLocalTime(date) : getOnlyDateValue(date);

    const setOtherZone = (date) =>
        !isDateOnly
            ? convertToTimeZone(date)
            : new Date(moment.utc(date).format('MM/DD/YYYY HH:mm:ss'));

    return (
        <Box display="flex" flexDirection="column" className={className}>
            {label ? (
                <Box display="flex" justifyContent="space-between">
                    <Label
                        label={label}
                        variant={!readOnly ? 'normal' : 'covision-input-label'}
                        labelClass={labelClass}
                    />
                    {isRequired && !readOnly && (
                        <Label
                            label="Required"
                            variant="outline"
                            labelClass="required-label"
                        />
                    )}
                </Box>
            ) : (
                <></>
            )}
            {readOnly ? (
                <Label
                    label={selected ? value : ''}
                    variant="normal"
                    labelClass="with-padding"
                />
            ) : (
                <DatePickerComponent
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    className="date-picker-calendar"
                    dateFormat={dateFormat}
                    placeholderText={placeholderText}
                    selected={
                        showTimeSelectOnly
                            ? selected
                            : selected
                            ? setOtherZone(selected)
                            : null
                    }
                    onChange={(v, e) => {
                        showTimeSelectOnly
                            ? onChange(v || null, e)
                            : onChange(v ? setLocalZone(v) : null, e);
                    }}
                    showTimeSelectOnly={showTimeSelectOnly}
                    popperModifiers={[
                        {
                            name: 'arrow',
                            options: { padding: 24 },
                        },
                    ]}
                    {...rest}
                />
            )}
            {isShowErrorMessage && errorMessage ? (
                <Label label={errorMessage} variant="error" />
            ) : (
                <></>
            )}
        </Box>
    );
};

export default DatePicker;
