import React from 'react';
import './Checkbox.scss';

// Checkbox

interface Props
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    variant?: 'default';
}

export const Checkbox: React.FC<Props> = (props) => {
    const { variant = 'default', id = 'cb1', children, ...rest } = props;
    return (
        <div className="checkbox-wrap">
            <input
                type="checkbox"
                id={id}
                name={id}
                className={`checkbox ${variant}`}
                disabled={rest.disabled || rest.readOnly}
                {...rest}
            />
            <label htmlFor={id}>
                <span className="wrap">
                    <span className="checkmark" />
                </span>
                <span>{children}</span>
            </label>
        </div>
    );
};
