import OktaAuth from '@okta/okta-auth-js';
import routesPaths from 'constants/routesPaths';

const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '{clientId}';
const ISSUER =
    process.env.REACT_APP_ISSUER || 'https://{yourOktaDomain}/oauth2/default';
const OKTA_TESTING_DISABLEHTTPSCHECK =
    process.env.REACT_APP_OKTA_TESTING_DISABLEHTTPSCHECK || false;
const REDIRECT_URI = routesPaths.LOGIN_CALLBACK;

// eslint-disable-next-line
const config = {
    oidc: {
        clientId: CLIENT_ID,
        issuer: ISSUER,
        redirectUri: REDIRECT_URI,
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
    },
    resourceServer: {
        messagesUrl: 'http://localhost:8000/api/messages',
    },
};

export const oktaAuth = new OktaAuth(config.oidc);
