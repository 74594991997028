import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import './CustomPopup.scss';

interface Props {
    open: boolean;
    scrollType?: 'body' | 'paper';
    popupClassName?: string;
    popupContainerClassName?: string;
    isMultiLevelPopup?: boolean;
    isFullWidth?: boolean;
}

const CustomPopup: React.FC<Props> = (props) => {
    const {
        open,
        children,
        scrollType = 'body',
        popupClassName,
        popupContainerClassName = '',
        isMultiLevelPopup = false,
        isFullWidth = false,
    } = props;

    React.useEffect(() => {
        if (!isMultiLevelPopup) {
            if (open) {
                document.body.style.overflowY = 'hidden';
            } else {
                document.body.style.overflowY = 'visible';
            }
        }
    }, [open, isMultiLevelPopup]);

    return (
        <Dialog
            className={`popup ${isFullWidth ? 'full-width-popup' : ''}`}
            open={open}
            scroll={scrollType}
            onFocus={() => {
                document.body.style.overflowY = 'hidden';
            }}
            onClick={(e) => e.stopPropagation()}
            maxWidth="lg"
            disableScrollLock
            disableEnforceFocus // in editor table selection popup issues was there so to prevent that we added this flag here
        >
            <div className={`max-size-popup ${popupContainerClassName ?? ''}`}>
                <div className={`popup-card ${popupClassName ?? ''}`}>
                    <>{children}</>
                </div>
            </div>
        </Dialog>
    );
};

export default CustomPopup;
