import { produce } from 'immer';
import { sortBy } from 'lodash';
import * as types from './locationTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    records: [],
    tableHeaders: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_LOCATIONS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_LOCATIONS.SUCCESS]: produce((draft, { total, locations }) => {
        const records = locations.map((item) => ({
            ...item,
            mapLink: item?.material?.name,
        }));
        const tableHeaders = [];
        // set header
        if (draft.tableHeaders.length === 0 && records.length > 0) {
            sortBy(Object.keys(records[0])).forEach((headerKey) => {
                tableHeaders.push({
                    field: headerKey,
                    headerName: headerKey,
                });
            });
            draft.tableHeaders = tableHeaders;
        }
        draft.records = records;
        draft.totalRecords = total;
        draft.loading = false;
    }),
    [types.GET_LOCATIONS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_LOCATIONS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
