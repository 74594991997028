import localStorageKeys from 'constants/localStorageKeys';

export default class Auth {
    static getStoredCredentials(): string | undefined {
        return localStorage.getItem(localStorageKeys.ACCESS_TOKEN) ?? undefined;
    }

    static clearStoredCredentials(): void {
        localStorage.removeItem(localStorageKeys.ACCESS_TOKEN);
    }
}
