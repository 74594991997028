import Label from 'uikit/labels/Labels/Labels';
import React from 'react';
import './Loader.scss';

/**
 * Loader: To show loader with or without label
 *
 * Example:
 *
 * <Loader label='Saving import' />
 *
 */

type Props = {
    label?: string;
};

export const Loader: React.FC<Props> = (props) => {
    const { label } = props;
    return (
        <div className="loader-container">
            <div className="loader">
                <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        opacity="1"
                        cx="8"
                        cy="8"
                        r="7"
                        stroke="#ddd"
                        strokeWidth="2"
                    />
                    <path
                        d="M12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85651 14.2625 11.637 12.9497 12.9497"
                        stroke="white"
                        strokeWidth="2"
                    />
                </svg>
                {label && <Label variant="default" label={label} />}
            </div>
        </div>
    );
};
