import { produce } from 'immer';
import * as types from './meetingsTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_MEETINGS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_MEETINGS.SUCCESS]: produce((draft, { total, meetings }) => {
        draft.records = meetings;
        draft.totalRecords = total;
        draft.loading = false;
    }),
    [types.GET_MEETINGS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_MEETINGS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
