import { produce } from 'immer';
import * as types from './usersTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_USERS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_USERS.SUCCESS]: produce((draft, { total, users }) => {
        const records = users;
        draft.records = records;
        draft.totalRecords = total;
        draft.loading = false;
    }),
    [types.GET_USERS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_USERS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
