export const API_URL =
    process.env.REACT_APP_API_URL || 'http://localhost:8000/api/v1/';

export const admin = '//cms.';
export const eventUrl = '/event';
export const participantWebUrl = window.location.origin.replace(admin, '//');
export const isAdminUser = window.location.origin.includes(admin);
export const hostname = new URL(window.location.origin)?.hostname.replace(
    'cms.',
    ''
);
export const isRunningLocally = hostname?.includes('localhost') || false;
export const HOST = isRunningLocally ? 'demo.covision.com' : hostname;
export const eventBrandingWebsite = '/web/';
export const mobileToken = 'mobile-token';
export const mobileType = 'mobile-type';

export const defaultLabelColor = '#000000';

export const appDetails = {
    title: 'Covision',
    iconUrl: '/favicon.ico',
};

export const defaultMaxTextLimit = 32767;

export const TABLE_NAME = {
    USER: 'User',
    AGENDA: 'SessionUser',
    SESSION: 'Session',
    MESSAGE: 'Communication',
    PARTICIPANT_PROFILE_IMPORT: 'ImportPhoto',
    EVENT: 'Event',
    GAME_POINTS: 'GameTaskPoint',
};

export const PROFILE_TYPES = {
    SPEAKER: 'speaker',
    USER: 'user',
};

export const COMPETITION_TYPES = {
    INDIVIDUAL: 'INDIVIDUAL',
    TEAM: 'TEAM',
};

export const RANK_BY = {
    TOTAL_POINTS: 'TOTAL_POINTS',
    WEIGHTED_POINTS: 'WEIGHTED_POINTS',
};

export const USER_ROLE = {
    SUPERADMIN: 'superadmin',
    EVENT_HUB_ADMIN: 'eventhubadmin',
    ORGANIZATION_ADMIN: 'organizationadmin',
    PARTICIPANT: 'participant',
    EVENT_ADMIN: 'eventadmin',
    MODERATOR: 'moderator',
    ADMIN: 'admin',
};

export const EXPORT_TEMPLATE = {
    USER: 'User_Template',
    AGENDA: 'Agenda_Template',
    SESSION: 'Sessions_Template',
    MESSAGE: 'Communication_Template',
    GAME_POINTS: 'Game_points_Template',
};

export const DATA_IMPORT_TYPES = {
    User: 'Participant',
    SessionUser: 'Agenda',
    Session: 'Session',
    ImportPhoto: 'Photo',
    Communication: 'Communication',
    GameTaskPoint: 'Game Task Point',
};

export const ENGAGEMENT_TOOL_QUESTION_TYPES = {
    TEXTBOX: 'TEXTBOX',
    MULTIPLE_CHOICE: 'RADIOBOX',
    CHECKBOXES: 'CHECKBOX',
    LINEAR_SCALE: 'LINEAR_SCALE',
    TEXT_FIELD: 'TEXT_FIELD',
};

export const sortOptions = [
    {
        value: 'desc',
        label: 'Newest question first',
    },
    {
        value: 'asc',
        label: 'Newest question last',
    },
];

export const viewTypes = {
    LIST_VIEW: 'LIST_VIEW',
    GRID: 'GRID',
    CAROUSEL: 'CAROUSEL',
    PILL: 'PILL',
};
export const defaultSelectedView = viewTypes.LIST_VIEW;

export const viewTypeOptions = [
    {
        value: viewTypes.LIST_VIEW,
        label: 'List View',
    },
    {
        value: viewTypes.GRID,
        label: 'Grid View',
    },
    {
        value: viewTypes.CAROUSEL,
        label: 'Carousel View',
    },
    {
        value: viewTypes.PILL,
        label: 'Pill View',
    },
];

export const pillTypes = {
    IMAGE_WITH_TITLE: 'IMAGE_WITH_TITLE',
    IMAGE_IN_CENTER: 'IMAGE_IN_CENTER',
    IMAGE_IN_FULL_SIZE: 'IMAGE_IN_FULL_SIZE',
};

export const defaultSelectedPillView = pillTypes.IMAGE_WITH_TITLE;

export const pillTypeOptions = [
    {
        value: pillTypes.IMAGE_WITH_TITLE,
        label: 'Image With Title',
    },
    {
        value: pillTypes.IMAGE_IN_CENTER,
        label: 'Image In Center',
    },
    {
        value: pillTypes.IMAGE_IN_FULL_SIZE,
        label: 'Image In Full Size',
    },
];

export const displayQAconfigurationTypes = {
    SHOW_LAST_QUESTION_ONLY: 'SHOW_LAST_QUESTION_ONLY',
    SHOW_ALL: 'SHOW_ALL',
    SHOW_SCROLL_THROUGH_LIST: 'SHOW_SCROLL_THROUGH_LIST',
};

export const displayTypeOptions = [
    {
        value: displayQAconfigurationTypes.SHOW_LAST_QUESTION_ONLY,
        label: 'Show last question only',
    },
    {
        value: displayQAconfigurationTypes.SHOW_ALL,
        label: 'Show all',
    },
    {
        value: displayQAconfigurationTypes.SHOW_SCROLL_THROUGH_LIST,
        label: 'Show scroll through list',
    },
];

export const displayQAEntriesTypes = {
    SHOW_ALL_ENTRIES: 'SHOW_ALL_ENTRIES',
    SHOW_ONLY_MARKED_AS_LIVE: 'SHOW_ONLY_MARKED_AS_LIVE',
};

export const entriesToShowOptions = [
    {
        value: displayQAEntriesTypes.SHOW_ALL_ENTRIES,
        label: 'Show all entries',
    },
    {
        value: displayQAEntriesTypes.SHOW_ONLY_MARKED_AS_LIVE,
        label: 'Show only marked as live entries',
    },
];

export const getConditionTypeBasedOnQuestionType = (qType) => {
    switch (qType) {
        case ENGAGEMENT_TOOL_QUESTION_TYPES.TEXTBOX:
            return constants.GLOBAL.STRING;
        case ENGAGEMENT_TOOL_QUESTION_TYPES.CHECKBOXES:
            return constants.GLOBAL.ENUM;
        case ENGAGEMENT_TOOL_QUESTION_TYPES.MULTIPLE_CHOICE:
            return constants.GLOBAL.ENUM;
        case ENGAGEMENT_TOOL_QUESTION_TYPES.LINEAR_SCALE:
            return constants.GLOBAL.NUMBER;
        default:
            break;
    }
};

export const getEnrollInfoMessage = (breakoutName) =>
    `This session is used in breakout "${breakoutName}" which is enrollable. So, rules will be applied from breakout`;

export const pageTypesForRoute = {
    DIRECTORY_PAGE: 'directory-page',
    FORM_PAGE: 'form-page',
};

export const pageTypes = {
    NORMAL_PAGE: 'NORMAL_PAGE',
    DIRECTORY_PAGE: 'DIRECTORY_PAGE',
    FORM_PAGE: 'FORM_PAGE',
    LOGIN_PAGE: 'LOGIN_PAGE',
    REGISTRATION_PAGE: 'REGISTRATION_PAGE',
    ACTIVITY_FEED_PAGE: 'ACTIVITY_FEED_PAGE',
    EXTERNAL_QR_CODE_PAGE: 'EXTERNAL_QR_CODE_PAGE',
    USER_PROFILE: 'USER_PROFILE',
    EDIT_PROFILE: 'EDIT_PROFILE',
};

export const formTypes = {
    NEW: 'NEW',
    EXISTING: 'EXISTING',
};
export const REGISTRATION_FORM = 'registration-form';
export const ENGAGEMENT_TOOLS_TYPES = {
    SURVEY: 'SURVEY',
    POLL: 'POLL',
    QA: 'QA',
    SOCIAL: 'SOCIAL',
    QUIZ: 'QUIZ',
};

export const linkedPage = 'linkedPage';

export const ENGAGEMENT_TOOLS_TYPES_LABELS = {
    SURVEY: 'Survey',
    POLL: 'Poll',
    QA: 'Q&A',
    QUIZ: 'Quiz',
};

export const ANONIMITY_LABELS = {
    ANONYMOUS: 'Anonymous',
    NOT_ANONYMOUS: 'Not anonymous',
    ALLOW_ANONYMOUS: 'Allow anonymous',
};

export const ANONIMITY_TYPES = {
    ANONYMOUS: 'ANONYMOUS',
    NOT_ANONYMOUS: 'NOT_ANONYMOUS',
    ALLOW_ANONYMOUS: 'ALLOW_ANONYMOUS',
};

export const LINKED_PAGE_MODULE_DATA = {
    CUSTOM_LINK_DATA_SEPARATOR: 'linkedPageData=',
    CUSTOM_LINK_VALUE_SEPARATOR: 'and',
    CUSTOM_LINK_VALUE: 'linkedPageValue=',
};

export const COMMON_REFRESH_INTERVAL = 60000;
export const COMMON_REFRESH_INTERVAL_SMALL = 3000;
export const COMMON_PAGE_SIZE = 300;

// we are removing past sessions from FE so total count and records visible not matched so to fix that we will load all sessions and show length based on sessions which we going to show
export const MODERATION_PAGE_SIZE = 100000;
export const VIEW_ALL_PAGE_SIZE = 1000000;

export const MAX_FILE_SIZE = 100000000; // 100000000Byte = 100MB
export const MAX_FILE_SIZE_SMALL = 10000000; // 10000000Byte = 10MB

export const HEIGHT_OF_LISTING_TABLE = 'calc(100% - 100px)';

export const DEFAULT_APP_DESIGNER_COLOR = {
    primaryColor: '#000000',
    secondaryColor: '#000000',
    backgroundColor: '#ffffff',
};

export const allowedImageTypes = ['jpeg', 'jpg', 'png'];
export const allowedVideoTypes = ['mp4', 'm4v', 'mov'];

export const hubReportsCategories = {
    EVENT: 'Event',
};

export const eventReportsCategories = {
    EVENT: 'Event',
    ATTENDEE: 'Attendee',
    ENGAGEMENT_TOOLS: 'Engagement tools',
    QR: 'QR',
};

export const reportTypes = {
    REGISTRATION_REPORT: 'registration-report',
    SURVEY_RESULT_REPORT: 'survey-results-report',
    AGGREGATED_SURVEY_RESULT_REPORT: 'aggregated-survey-results-report',
    AGGREGATED_POLL_RESULT_REPORT: 'aggregated-poll-results-report',
    QUIZ_RESULT_REPORT: 'quiz-results-report',
    POLL_RESULT_REPORT: 'poll-results-report',
    QA_REPORT: 'qa-report',
    QR_CODE_REPORT: 'qr-codes-report',
    ATTENDEES_REPORT: 'attendees-report',
    SESSION_QR_CODE_REPORT: 'session-qr-codes-report',
    EVENT_ACCESSED_REPORT: 'event-accessed-report',
    MEETINGS_REPORT: 'meetings-report',
    ATTENDEES_PHOTO_REPORT: 'attendees-photo-report',
    ENROLLMENT_REPORT_FOR_SESSIONS_AND_BREAKOUTS:
        'enrollment-reports-for-sessions-and-breakouts',
    ATTENDEES_DOWNLOADED_APP_VERSIONS_REPORT:
        'attendees-downloaded-app-versions-report',
    MAGIC_LINKS_REPORT: 'magic-links-report',
};

export const reportColumns = {
    photoAvailable: 'Photo Available?',
    eventAccessed: 'Event Accessed?',
    eventAccessedTime: 'Accessed Time',
    firstName: 'First Name',
    lastName: 'Last Name',
    appDownloadedDate: 'iOS Download Date',
    appDownloadedVersion: 'iOS Version',
    appDownloadedDateAndroid: 'Android Download Date',
    appDownloadedVersionAndroid: 'Android Version',
};

export const PREVIEW = 'preview';
/**
 * we used this because /event was used on all urls so to know that the user is tring to
 * login to specific event using  event url. So, we used unique identifier which was not
 * in used anywhere
 */
export const EVENT_URL = 'event-url';
export const EVENT_REDIRECTION_URL = 'download-app';
export const EVENT_LINK = `${participantWebUrl}/${EVENT_URL}`;
export const PARTICIPANT_UI_PATH = '/event/:eventId/:pageId';
export const DIRECTORY_ITEM = 'item';
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'demo';

export const MODULE_ITEMS = {
    DISCUSSION: 'DISCUSSION',
    MAP: 'MAP',
    CONTAINER: 'CONTAINER',
    DIRECTORY: 'DIRECTORY',
    DOCUMENT: 'DOCUMENT',
    LIBRARY: 'LIBRARY',
    LINKED_PAGE: 'LINKED_PAGE',
    TEXT: 'TEXT',
    IMAGE: 'IMAGE',
    VIDEO: 'VIDEO',
    LINK_LIST: 'LINK_LIST',
    MY_AGENDA: 'MY_AGENDA',
    MY_AGENDA_LIST: 'MY_AGENDA_LIST',
    DIRECTORY_ITEM: 'DIRECTORY_ITEM',
    SESSION_ITEM: 'SESSION_ITEM',
    NOTIFICATION_MESSAGES: 'NOTIFICATION_MESSAGES',
    GAME_PAGE: 'GAME_PAGE',
};

export const WEBSITE_LINK_TYPES = {
    NORMAL: 'NORMAL',
    IMAGE: 'IMAGE',
    BUTTON: 'BUTTON',
};

export const APP_DESIGNER_LABELS = {
    BRANDING: 'Branding',
};

const apiEndPointsForEngagementTools = {
    SURVEYS: 'surveys',
    POLLS: 'polls',
    QAS: 'qa',
    QUIZ: 'quizzes',
};

export const messageModule = {
    COMMUNICATION: 'Notification',
    COMMUNICATIONS: 'Notifications',
    EMAIL: 'Email',
    EMAILS: 'Emails',
};

export const EDIT_PROFILE = 'edit-profile';

export const DATE_AND_TIME = {
    startDate: 'startDate',
    endDate: 'endDate',
    startTime: 'startTime',
    endTime: 'endTime',
    scheduleDate: 'scheduleDate',
    scheduleTime: 'scheduleTime',
};

/**
 * use this configuration in table defination and also use below css for auto width feature
    .ag-cell-wrapper {
        width: 100%;
    }
    .ag-cell-value {
        width: inherit;
    }
*/

export const commonConfigurationForAutoHeightInputBoxInAggidTable = {
    autoHeight: true,
    cellStyle: {
        'white-space': 'normal',
        'min-height': '41px',
        'padding-top': '10px',
        'padding-bottom': '10px',
        'word-break': 'break-all',
    },
};

export const directoryItems = {
    ALL_PARTICIPANTS: 'ALL_PARTICIPANTS',
    ALL_SPEAKERS: 'ALL_SPEAKERS',
    SESSION_SPEAKERS: 'SESSION_SPEAKERS',
    CUSTOM: 'CUSTOM',
    BUSINESS_CARD_EXCHANGED: 'BUSINESS_CARD_EXCHANGED',
    USERS_AVAILABLE_FOR_MEETINGS: 'USERS_AVAILABLE_FOR_MEETINGS',
};

export const linkPageItems = {
    PAGE_OPTION: 'PAGE_OPTION', // NORMAL_PAGE
    URL: 'URL',
    GAME_PAGE: 'GAME_PAGE',
    MY_AGENDA: 'MY_AGENDA',
    DIRECTORY_PAGE: pageTypes.DIRECTORY_PAGE,
    FORM_PAGE: pageTypes.FORM_PAGE,
    ACTIVITY_FEED_PAGE: pageTypes.ACTIVITY_FEED_PAGE,
    EXTERNAL_QR_CODE_PAGE: pageTypes.EXTERNAL_QR_CODE_PAGE,
    HOME: 'HOME',
    MATERIAL: 'MATERIAL',
    ENGAGEMENT_TOOL: 'ENGAGEMENT_TOOL',
    SCANNER: 'SCANNER',
    BUSINESS_CARD_EXCHANGE_SCANNER: 'BUSINESS_CARD_EXCHANGE_SCANNER',
    ACCESS_CONTROL: 'ACCESS_CONTROL',
    LOGIN_PAGE: pageTypes.LOGIN_PAGE,
    REGISTRATION_PAGE: pageTypes.REGISTRATION_PAGE,
    USER_PROFILE: pageTypes.USER_PROFILE,
    EDIT_PROFILE: pageTypes.EDIT_PROFILE,
    EVENT_WEBSITE: 'EVENT_WEBSITE',
};

export const linksToShowByDefault = [
    linkPageItems.HOME,
    linkPageItems.MY_AGENDA,
];
export const linksWeDontShowOnWebView = [
    linkPageItems.SCANNER,
    linkPageItems.BUSINESS_CARD_EXCHANGE_SCANNER,
    linkPageItems.ACCESS_CONTROL,
];
export const tableRowHeight = 51;

export const TABALE_COLUMN_TYPES = {
    USER: 'USER',
    NORMAL: 'NORMAL',
    IMAGE: 'IMAGE',
};

export const themesArray = [
    {
        value: 'BLACK_AND_WHITE',
        label: 'Black and white',
        data: {
            primaryColor: '#000000',
            secondaryColor: '#ffffff',
        },
    },
    {
        value: 'PINK_AND_RAISIN',
        label: 'Pink and raisin',
        data: {
            primaryColor: '#e52165',
            secondaryColor: '#0d1137',
        },
    },
    {
        value: 'RED_AND_SEAFOAM',
        label: 'Red and sea-foam',
        data: {
            primaryColor: '#d72631',
            secondaryColor: '#a2d5c6',
        },
    },
    {
        value: 'YELLO_AND_MAGENTA',
        label: 'Yellow and magenta',
        data: {
            primaryColor: '#e2d810',
            secondaryColor: '#d9138a',
        },
    },
    {
        value: 'MUSTARD_AND_BLACK',
        label: 'Mustard and black',
        data: {
            primaryColor: '#f3ca20',
            secondaryColor: '#000000',
        },
    },
    {
        value: 'MAGENTA_AND_GOLDENROD',
        label: 'Magenta and goldenrod',
        data: {
            primaryColor: '#cf1578',
            secondaryColor: '#e8d21d',
        },
    },
    {
        value: 'GOLD_AND_CHARCOAL',
        label: 'Gold and charcoal',
        data: {
            primaryColor: '#ef9d10f',
            secondaryColor: '#3b4d61',
        },
    },
    {
        value: 'NAVY_AND_ALMOND',
        label: 'Navy and almond',
        data: {
            primaryColor: '#1e3d59',
            secondaryColor: '#f5f0e1',
        },
    },
    {
        value: 'TAN_AND_DEEPTURQUOISE',
        label: 'Tan and deep turquoise',
        data: {
            primaryColor: '#ecc19c',
            secondaryColor: '#1e847f',
        },
    },
    {
        value: 'MAUVE_AND_SAPPHIRE',
        label: 'Mauve and sapphire',
        data: {
            primaryColor: '#d9a5b3',
            secondaryColor: '#1868ae',
        },
    },
    {
        value: 'BLUE_AND_MAROON',
        label: 'Blue and maroon',
        data: {
            primaryColor: '#408ec6',
            secondaryColor: '#7a2048',
        },
    },
];

export const eventActions = {
    CLONE: 'Clone event',
    DELETE: 'Delete event',
};

export const operators = {
    EQUAL: 'EQUAL',
    NOT_EQUAL: 'NOT_EQUAL',
    LESS_THAN_EQUAL: 'LESS_THAN_EQUAL',
    GREATER_THAN_EQUAL: 'GREATER_THAN_EQUAL',
    LESS_THAN: 'LESS_THAN',
    GREATER_THAN: 'GREATER_THAN',
    INCLUDES: 'INCLUDES',
};

export const itemsForCloneEvent = {
    participants: 'participants',
    sessions: 'sessions',
    materials: 'materials',
    locations: 'locations',
    appDesigner: 'appDesigner',
    webDesigner: 'webDesigner',
    labels: 'labels',
};

export const LOCATION_TYPES = {
    MAP_LINK: 'mapLink',
    PDF_MAP_LINK: 'materialId',
    MAP_LOCATION_URL: 'mapLocationUrl',
};

export const QR_CODE_ACTION_TYPES = {
    SHOW_MESSAGE: 'showMessage',
    OPEN_LINK: 'openLink',
};

export const enrollmentStatus = {
    ENROLLMENT_CLOSED: 'Enrollment has closed.',
};

export const participantLabels = {
    PARTICIPANTS: 'Attendees',
    PARTICIPANT: 'Attendee',
};

export const CREATE_A_NEW_INSTANCE = 'CREATE_A_NEW_INSTANCE';

export const createNewInstance = {
    label: 'Create a new instance',
    value: CREATE_A_NEW_INSTANCE,
};

export const commonStatus = {
    OPEN: 'OPEN',
    CLOSED: 'CLOSED',
    SCHEDULED: 'SCHEDULED',
};

export const pointsType = {
    COMPLETION: 'COMPLETION',
    TYPE_POINTS: 'TYPE_POINTS',
};

export const generateIntervalArray = (endInterval, interval) => {
    const result = [];

    for (let i = interval; i <= endInterval; i += interval) {
        result.push({
            label: `${i.toString()} min`,
            value: i,
        });
    }

    return result;
};

export const gameTaskTypeOptionsValues = {
    SURVEY: 'SURVEY',
    QUIZ: 'QUIZ',
    ACTIVITY_FEED: 'ACTIVITY_FEED',
    QA: 'QA',
    VIEW_DOCUMENT: 'VIEW_DOCUMENT',
    ATTEND_SESSION: 'ATTEND_SESSION',
    REGISTER_SESSION: 'REGISTER_SESSION',
    POPULATE_PROFILE: 'POPULATE_PROFILE',
    REGISTRATION_FORM: 'REGISTRATION_FORM',
    SIGNUP_MEETING: 'SIGNUP_MEETING',
    DOWNLOAD_APP: 'DOWNLOAD_APP',
    SCAN_QR: 'SCAN_QR',
    BUSINESS_CARD_EXCHANGE: 'BUSINESS_CARD_EXCHANGE',
};

export const gameTaskTypeOptionsLabels = {
    SURVEY: 'Take Survey',
    QUIZ: 'Take Quizzes',
    ACTIVITY_FEED: 'Post Activity Feed',
    QA: 'Send question to Q&A tool',
    VIEW_DOCUMENT: 'View Document',
    ATTEND_SESSION: 'Attend session(s)',
    REGISTER_SESSION: 'Register for sessions?',
    POPULATE_PROFILE: 'Populating your profile',
    REGISTRATION_FORM: 'Registration form',
    SIGNUP_MEETING: 'Signup for hosted meeting',
    DOWNLOAD_APP: 'Downloading app',
    SCAN_QR: 'Scan QR codes',
    BUSINESS_CARD_EXCHANGE: 'Business card exchange',
};

export const gameTaskSubTypesValues = {
    ANY: 'ANY',
    SPECIFIC: 'SPECIFIC',
};

const constants = {
    BASE_URL: {
        API: `${API_URL}`,
    },
    API: {
        DATA_IMPORT: {
            SAVE_IMPORT_DATA_TEMPLATE: 'events/:eventId/data-import/importFile',
            EXPORT_TEMPLATE: 'events/:eventId/data-import/exportDefaultFormat',
            VERIFY_FILE_BEFORE_IMPORT:
                'events/:eventId/data-import/verifyImportFile',
            GET_EXISTING_COLUMNS:
                'events/:eventId/data-import/getExistingColumnAndDataType',
            GET_DATA_TYPE: 'events/:eventId/data-import/getDataType',
            GET_DATA_IMPORTED_LIST: 'events/:eventId/data-import/importSummary',
            GET_IMPORTED_DATE_HISTORY_LIST:
                'events/:eventId/data-import/importSummaryDetail',
            GET_EXISITNG_SESSIONS: 'events/:eventId/data-import/sessions',
            IMPORT_ORIGINAL_FILE:
                'events/:eventId/data-import/downloadOriginalImportFile',
            VERIFY_EMAIL: 'events/:eventId/data-import/verifyEmail',
            VERIFY_SESSION_ID: 'events/:eventId/data-import/verifySessionId',
            VERIFY_COMMUNICATION_ID:
                'events/:eventId/data-import/verifyCommunicationId',
            VERIFY_GAME_EMAIL:
                'events/:eventId/data-import/verifyGameTaskPointsEmail',
            VERIFY_AGENDA_NAME: 'events/:eventId/data-import/verifyAgendaName',
            UPLOAD_FILE: 'events/:eventId/data-import/uploadFile',
            VERIFY_PROFILE_EMAIL:
                'events/:eventId/data-import/verifyProfileEmail',
            UPLOAD_USER_PROFILE:
                'events/:eventId/data-import/uploadUserProfile',
            GET_IMPORT_HISTORY_ID:
                'events/:eventId/data-import/importProfileFile',
            UPDATE_COUNT_IN_IMPORT_HISTORY:
                'events/:eventId/data-import/importSummaryDetail',
            EXPORT_SESSION_ATTENDEES:
                'events/:eventId/sessions/export-attendance-tracking',
        },
        THIRD_PARTY_IMPORT: {
            INTEGRATION: 'events/:eventId/import-integrations/:id',
            INTEGRATIONS: 'events/:eventId/import-integrations',
            GET_IMPORTED_HISTORY_LIST:
                'events/:eventId/import-integrations/:id/historyDetails',
            SYNC_MAPPING_KEYS:
                'events/:eventId/import-integrations/:importIntegrationId/sync',
            IMPORT_THIRD_PARTY_DATA:
                'events/:eventId/import-integrations/:importIntegrationId/import',
            INTEGRATIONS_FILTER: 'events/:eventId/import-integrations/filters',
            TEST_CONNECTION:
                'events/:eventId/import-integrations/test-connection',
            PAST_HOSTORY: {
                INTEGRATION_PAST_HOSTORY:
                    'events/:eventId/import-integrations/:importIntegrationId/history',
            },
        },
        LOCATIONS: {
            LOCATION: 'events/:eventId/locations/:id',
            LOCATIONS: 'events/:eventId/locations',
            LOCATIONS_FILTER: 'events/:eventId/locations/filters',
        },
        COMMUNICATIONS: {
            COMMUNICATION: 'events/:eventId/communications/:id',
            COMMUNICATIONS: 'events/:eventId/communications',
            COMMUNICATIONS_ARCHIVE: 'events/:eventId/communications/archive',
            COMMUNICATIONS_FILTER: 'events/:eventId/communications/filters',
        },
        EMAILS: {
            EMAIL: 'events/:eventId/emails/:id',
            EMAILS: 'events/:eventId/emails',
            EMAILS_FILTER: 'events/:eventId/emails/filters',
        },
        MATERIALS: {
            MATERIALS: 'events/:eventId/materials',
            MATERIAL: 'events/:eventId/materials/:materialId',
            MATERIALS_FILTER: 'events/:eventId/materials/filters',
        },
        DIRECTORIES: {
            DIRECTORY: 'events/:eventId/locations/:id',
            DIRECTORIES: 'events/:eventId/locations',
            DIRECTORIES_FILTER: 'events/:eventId/locations/filters',
        },
        ENGAGEMENT_TOOLS: {
            ENGAGEMENT_TOOL: 'engagement-tools/:id',
            ENGAGEMENT_TOOLS: 'engagement-tools',
            ENGAGEMENT_TOOLS_WITH_EVENT_SPECIFIC_INSTANCE:
                'events/:eventId/engagement-tools',
            ENGAGEMENT_TOOLS_EDIBALE_OR_NOT:
                'engagement-tools/quizzes/:engagementToolId/check-quiz-editable',
            ENGAGEMENT_TOOLS_WITH_TYPE: 'engagement-tools/:type',
            ENGAGEMENT_TOOLS_FILTER: 'engagement-tools/filters',
            ENGAGEMENT_TOOLS_INSTANCE:
                'engagement-tools/:engagementToolId/instances/:instanceId',
            ENGAGEMENT_TOOLS_RESULT_SCREEN_UPDATE_OPTIONS:
                // 'engagement-tools/:engagementToolId/instances/:instanceId',
                'events/:eventId/sessions/update-options',
            ENGAGEMENT_TOOLS_INSTANCES:
                'engagement-tools/:engagementToolId/instances',
            ENGAGEMENT_TOOLS_INSTANCES_ANSWER:
                'engagement-tools/:engagementToolId/instances/:instanceId/answer',
            ENGAGEMENT_TOOLS_INSTANCES_ANSWER_SURVEY_SUBMIT:
                'engagement-tools/:engagementToolId/instances/:instanceId/answers/survey/submit',
            ENGAGEMENT_TOOLS_INSTANCES_ANSWER_SURVEY:
                'engagement-tools/:engagementToolId/instances/:instanceId/answers/survey',
            QUIZ_INSTANCES_ANSWER:
                'engagement-tools/:engagementToolId/instances/:instanceId/answers/quiz',
            ENGAGEMENT_TOOLS_INSTANCES_ASK:
                'engagement-tools/:engagementToolId/instances/:instanceId/ask',
            ENGAGEMENT_TOOLS_AVAILABILITY:
                'engagement-tools/:engagementToolId/instances/:instanceId/check-survey-poll-availability',
            QUIZ_AVAILABILITY:
                'engagement-tools/:engagementToolId/instances/:instanceId/check-quiz-availability',
            SUBMIT_QUIZ:
                'engagement-tools/:engagementToolId/instances/:instanceId/answers/quiz/submit',
        },
        GAMIFICATIONS: {
            GAMIFICATION: 'events/:eventId/games/:id',
            GAMIFICATIONS: 'events/:eventId/games',
            EXPORT_GAME_AUDIENCE:
                'events/:eventId/games/:gameId/game-task-points/export',
            GAME_FOR_PREVIEW: 'events/:eventId/games/:gameId/view',
            GAMIFICATIONS_FILTER: 'events/:eventId/games/filters',
            GAMIFICATIONS_FRONT_OF_ROOM_LEADER_BOARD:
                'events/:eventId/games/:gameId/leaderboard',
            GAMIFICATIONS_UPDATE_OPTIONS:
                'events/:eventId/games/update-options',
            TASKS: {
                TASK: 'events/:eventId/games/:gameId/game-tasks/:gameTaskId',
                TASKS: 'events/:eventId/games/:gameId/game-tasks',
                TASKS_FILTER:
                    'events/:eventId/games/:gameId/game-tasks/filters',
                TASKS_UPDATE_OPTIONS:
                    'events/:eventId/games/:gameId/game-tasks/update-options',
                REORDER_TASKS: 'events/:eventId/games/:gameId/reorder',
            },
            BADGES: {
                BADGE: 'events/:eventId/games/:gameId/game-achievements/:gameBadgeId',
                BADGES: 'events/:eventId/games/:gameId/game-achievements',
                BADGES_FILTER:
                    'events/:eventId/games/:gameId/game-achievements/filters',
                REORDER_BADGES:
                    'events/:eventId/games/:gameId/game-achievements/reorder',
            },
            ASSIGNED_POINTS: {
                ASSIGNED_POINT:
                    'events/:eventId/games/:gameId/game-task-points/:gameTaskPointId',
                ASSIGNED_POINTS:
                    'events/:eventId/games/:gameId/game-task-points',
                ASSIGNED_POINTS_FILTER:
                    'events/:eventId/games/:gameId/game-task-points/filters',
                ASSIGNED_POINTS_UPDATE_OPTIONS:
                    'events/:eventId/games/:gameId/game-task-points/update-options',
            },
        },
        MEETINGS: {
            MEETING: 'events/:eventId/meetings/:id',
            MEETINGS: 'events/:eventId/meetings',
            MEETING_INTERVAL:
                'events/:eventId/meetings/:meetingId/intervals/:intervalId',
            MEETINGS_INTERVALS: 'events/:eventId/meetings/:meetingId/intervals',
            MEETINGS_INTERVALS_UPDATE_LOCATION:
                'events/:eventId/meetings/:meetingId/intervals/update-locations',
            MEETING_INTERVALS_FILTERS:
                'events/:eventId/meetings/:meetingId/intervals/filters',
            MEETINGS_INTERVALS_UPDATE_OPTIONS:
                'events/:eventId/meetings/:meetingId/intervals/update-options',
            AVAILABLE_USERS: 'events/:eventId/participants',
            AVAILABLE_USERS_FILTER: 'events/:eventId/participants/filters',
            EXPORT_MEETING_REPORT:
                'events/:eventId/meetings/:meetingId/intervals/export',
        },
        CUSTOM_FIELDS: {
            CUSTOM_FIELDS: 'events/:eventId/custom-fields',
            CUSTOM_FIELD: 'events/:eventId/custom-fields/:custom-field-id',
        },
        LABELS: {
            LABEL: 'events/:eventId/labels/:id',
            LABELS: 'events/:eventId/labels',
            LABELS_FILTER: 'events/:eventId/labels/filters',
            LABELS_AUDIENCE: 'events/:eventId/labels/:labelId/check',
            CUSTOM_FIELDS_MAP: 'events/:eventId/custom-fields/map',
        },
        COLLECTIONS: {
            COLLECTIONS: 'events/:eventId/collections',
        },
        PARTICIPANTS: {
            PARTICIPANTS: 'events/:eventId/participants',
        },
        BREAKOUTS: {
            BREAKOUTS: 'events/:eventId/breakouts',
            BREAKOUTS_UPDATE_OPTIONS:
                'events/:eventId/breakouts/update-options',
        },
        CUSTOM_QR_CODES: {
            CUSTOM_QR_CODES: 'events/:eventId/qrs',
            CUSTOM_QR_CODES_FILTER: 'events/:eventId/qrs/filters',
        },
        COMMON: {
            EXPORT_RECORDS: 'events/:eventId/:exportType/export',
            EXPORT_MAGIC_LINKS: 'events/:eventId/participants/magic-links',
            EXPORT_FORM_RECORDS:
                'events/:eventId/pages/:pageId/export-by-reg-form',
            VIEW_SETTINGS: 'view-settings',
            USER_DETAILS: 'users/:id',
            USER_PROFILE: 'events/:eventId/participants/user-profile',
        },
        EVENTS: {
            EVENTS: 'events',
            EVENTS_FILTER: 'events/filters',
            EVENTS_ACTION: 'events/:eventId',
            EVENTS_UPDATE_OPTIONS: 'events/update-options',
            CLONE_EVENT_OPTIONS_WITH_COUNT: 'events/:eventId/clone',
            UNREAD_MESSAGES: 'events/:eventId/communications/view/unread',
            CLONE_EVENT_ACTION: 'events/:eventId/clone',
            DATE_SHIFT_ACTION: 'events/:eventId/date-shift',
            DATA_TAGS: 'events/:eventId/participants/get-available-fields',
            DATA_TAGS_VALUES:
                'events/:eventId/participants/get-available-fields-data',
        },
        SUPER_ADMIN: {
            ORGANIZATIONS: {
                ORGANIZATIONS: 'organizations',
                ORGANIZATIONS_FILTER: 'organizations/filters',
            },
            EVENT_HUBS: {
                EVENT_HUBS: 'event-hubs',
                EVENT_HUBS_FILTER: 'event-hubs/filters',
                EVENT_HUBS_UPDATE_OPTIONS: 'event-hubs/update-options',
            },
            APP_VERSIONS: {
                APP_VERSIONS: 'mobile-versions',
                APP_VERSIONS_FILTER: 'mobile-versions/filters',
                APP_VERSIONS_UPDATE_OPTIONS: 'mobile-versions/update-options',
            },
            EVENTS: {
                EVENTS: 'events',
                EVENTS_FILTER: 'events/filters',
            },
            ADMIN_USERS: {
                ADMIN_USERS: 'users/admin',
                ADMIN_USERS_UPDATE_OPTIONS: 'users/admin/update-options',
                ADMIN_USERS_FILTER: 'users/admin/filters',
            },
        },
        REGISTRATIONS: {
            REGISTRATIONS: 'events/:eventId/materials',
            REGISTRATIONS_FILTER: 'events/:eventId/materials/filters',
        },
        ACTIVITY_FEED: {
            POSTS: 'events/:eventId/pages/:pageId/posts',
            POST: 'events/:eventId/pages/:pageId/posts/:postId',
            COMMENTS: 'events/:eventId/pages/:pageId/posts/:postId/comments',
            LIKE_POST: 'events/:eventId/pages/:pageId/posts/:postId/like',
            LIKE_COMMENT:
                'events/:eventId/pages/:pageId/posts/:postId/comments/:commentId/like',
        },
        APP_DESIGNER: {
            PAGES: 'events/:eventId/pages',
            WIDGETS: 'events/:eventId/pages/:pageId/widgets',
            WIDGET_BYID: 'events/:eventId/pages/:pageId/widgets/:widgetId',
            REORDER_WIDGETS: 'events/:eventId/pages/:pageId/reorder',
        },
        WEB_DESIGNER: {
            WEB_EVENT: 'events/:eventId/preview',
            PAGES: 'events/:eventId/web-pages',
            WIDGETS: 'events/:eventId/web-pages/:pageId/widgets',
            WEB_DIRECTORY: 'events/:eventId/web-pages/view/:pageId/directory',
            WEB_DIRECTORY_FILTERS:
                'events/:eventId/web-pages/view/:pageId/directory/filters',
            REORDER_WIDGETS: 'events/:eventId/web-pages/:pageId/reorder',
        },
        APP_DESIGNER_WEB_VIEW: {
            PAGES: 'events/:eventId/pages/view',
            WIDGETS: 'events/:eventId/pages/:pageId/widgets/view',
            WIDGET_BYID: 'events/:eventId/pages/:pageId/widgets/view/:widgetId',
        },
        SURVEY_POLL_RESULT: {
            SURVEY_POLL_RESULT: '/view-result/:instanceId',
        },
        NOTIFICATION_MESSAGES: {
            NOTIFICATION_MESSAGES: 'events/:eventId/communications/view',
            NOTIFICATION_MESSAGE:
                'events/:eventId/communications/:communicationId/view',
        },
    },
    ROUTE: {
        LOGIN: '/login',
    },
    GLOBAL: {
        CREATE: 'CREATE',
        ERROR: 'ERROR',
        UPDATE: 'UPDATE',
        NONE: 'NONE',
        EMAIL: 'email',
        PHONE: 'phone',
        // to show date on ui will use this format
        DATE_FORMATE_FOR_DISPLAY: 'MMM DD, YYYY',
        DATE_TIME_FORMATE_FOR_DISPLAY: 'MMM DD, YYYY, hh:mm A',
        DATE_TIME_FORMATE_FULL_MONTH_FOR_DISPLAY: 'MMMM DD, YYYY, hh:mm A',
        TIME_FORMATE_FOR_DISPLAY: 'hh:mm A',
        DATE_TIME_FORMATE_FOR_DISPLAY_AGENDA_IMPORT_2: 'MMM DD, YYYY, hh:mm A',
        // to pass date in backend will use this format
        DATE_TIME_FORMATE: 'YYYY-MM-DD hh:mm A',
        DATE_TIME_FORMATE_MMDDYYYY_DISPLAY: 'MM/DD/YYYY, hh:mm A',
        DATE_TIME_WITH_SECOND_FORMATE: 'YYYY-MM-DD HH:mm:ss',
        DATE_FORMATE: 'YYYY-MM-DD',
        MMDDYYYY_DATE_FORMATE: 'MM-DD-YYYY',
        FILTER_RECORDS_NOT_FOUND:
            'We can not find any records matching your search or filter criteria. Please change criteria and try again.',
        REQUIRED_FILED: 'Required field',
        START_DATE_CAN_NOT_BE_GREATER_THAN_END_DATE:
            'Start date can not be greater than end date',
        END_DATE_CAN_NOT_BE_LESS_THAN_START_DATE:
            'End date can not be less than start date',
        START_TIME_CAN_NOT_BE_GREATER_THAN_END_TIME:
            'Start time can not be greater than end time',
        END_TIME_CAN_NOT_BE_LESS_THAN_START_TIME:
            'End time can not be less than start time',
        TIME_FORMAT_SHOULD_BE_LIKE: 'Time format should be like: hh:mm AM/PM',
        FIELD_TYPES: {
            INPUT: 'input',
            TWO_INPUT: 'two-input',
        },
        STRING: 'STRING',
        PROFILE_LINK: 'PROFILE_LINK',
        PASSWORD: 'PASSWORD',
        NUMBER: 'NUMBER',
        DATE: 'DATE',
        BOOLEAN: 'BOOLEAN',
        ADDRESS: 'ADDRESS',
        CURRENCY: 'CURRENCY',
        ENUM: 'ENUM',
        TEXT_FIELD: 'TEXT_FIELD',
        SECTION: 'SECTION',
        ROW: 'ROW',
        COLUMN: 'COLUMN',
        LABELS: 'LABELS',
        COLLECTIONS: 'COLLECTIONS',
        BREAKOUT: 'BREAKOUT',
        SPEAKERS: 'SPEAKERS',
        MODERATORS: 'MODERATORS',
        AUDIENCE: 'AUDIENCE',
        EXCLUSIONS: 'EXCLUSIONS',
        DATE_AND_TIME: 'DATE_AND_TIME',
        DATES: 'DATES',
        TIMES: 'TIMES',
        TIME: 'TIME',
        START_END_DATE_AND_TIME: 'START_END_DATE_AND_TIME',
        DATA_TAGS: 'Tags',
        EMPTY_ANSWER: 'No response',
        SEND_LIVE: 'Send Live',
        MAX_LENGTH_EXCEEDED: 'Max length exceeded',
        NEW_MODULE: 'NEW_MODULE',
        AGGREGATED_RESULT: 'aggregated-result',
    },
    COMPONENTS: {
        ALERT_TYPES: {
            DEFAULT_ALERT: 'DefaultAlert',
            ERROR_ALERT: 'ErrorAlert',
            SUCCESS_ALERT: 'SuccessAlert',
            WARNING_ALERT: 'WarningAlert',
            LOADING_ALERT: 'LoadingAlert',
        },
        DROPDOWN: {
            IGNORE_COLUMN: '- - IGNORE COLUMN - -',
            IGNORE_ITEM: '- - IGNORE - -',
            CREATE_NEW_FIELD: 'CREATE_NEW_FIELD',
            ADD_NEW_VALUE: 'ADD_NEW_VALUE',
            DATA_TYPES: [
                {
                    value: '1',
                    label: participantLabels.PARTICIPANTS,
                    data: {
                        fileTypesAllowed: ['xlsx', 'csv'],
                        tableName: TABLE_NAME.USER,
                        exportFileName: EXPORT_TEMPLATE.USER,
                        showCreateCustomField: true,
                    },
                },
                {
                    value: '2',
                    label: 'Agendas',
                    data: {
                        fileTypesAllowed: ['xlsx'],
                        tableName: TABLE_NAME.AGENDA,
                        exportFileName: EXPORT_TEMPLATE.AGENDA,
                        showCreateCustomField: false,
                    },
                },
                {
                    value: '3',
                    label: 'Sessions',
                    data: {
                        fileTypesAllowed: ['xlsx'],
                        tableName: TABLE_NAME.SESSION,
                        exportFileName: EXPORT_TEMPLATE.SESSION,
                        showCreateCustomField: false,
                    },
                },
                {
                    value: '4',
                    label: 'Communications',
                    data: {
                        fileTypesAllowed: ['xlsx'],
                        tableName: TABLE_NAME.MESSAGE,
                        exportFileName: EXPORT_TEMPLATE.MESSAGE,
                        showCreateCustomField: false,
                    },
                },
            ],
            YES_NO: [
                { value: 'Y', label: 'TRUE' },
                { value: 'N', label: 'FALSE' },
            ],
            TRUE_FALSE: [
                { value: '', label: 'SELECT' },
                { value: 'true', label: 'Y' },
                { value: 'false', label: 'N' },
            ],
        },
        TABLE_DATA_TYPES: {
            DATE: 'date', // this is used for custom filed date datatype
            NORMAL_DATE: 'normal date', // this is used for session startDate and endDate
            TIME: 'time', // this is used for session startTime and endTime
            BOOLEAN: 'boolean',
            USER_DEFINED_ENUM: 'user-defined',
            ENUM: 'enum',
            INTEGER: 'integer',
            NUMBER: 'number',
        },
        BULK_UPDATE: {
            INPUT_WITH_OPTIONS: 'INPUT_WITH_OPTIONS',
            INPUT_BOX: 'INPUT_BOX',
            CUSTOM_INPUT: 'CUSTOM_INPUT',
            PASSWORD: 'password',
        },
        PAGINATION: {
            PAGINATION_LIMIT: [
                {
                    label: COMMON_PAGE_SIZE,
                    value: COMMON_PAGE_SIZE,
                },
                {
                    label: 1000,
                    value: 1000,
                },
                {
                    label: 'View All',
                    value: VIEW_ALL_PAGE_SIZE,
                },
            ],
        },
    },
    SCREENS: {
        AGENDA: {
            ADDED: 'Added +',
            REMOVED: 'Removed -',
        },
        SESSION: {
            START_AT: DATE_AND_TIME.startDate,
            END_AT: DATE_AND_TIME.endDate,
            START_TIME: DATE_AND_TIME.startTime,
            END_TIME: DATE_AND_TIME.endTime,
        },
        LOCATION: {
            LOCATION_OPTIONS: [
                {
                    label: 'Select (Optional)',
                    value: '',
                },
                {
                    label: 'Map Location',
                    value: LOCATION_TYPES.MAP_LOCATION_URL,
                },
                {
                    label: 'PDF',
                    value: LOCATION_TYPES.PDF_MAP_LINK,
                },
            ],
        },
        CUSTOM_QR_CODE: {
            ACTION_OPTIONS: [
                {
                    label: 'Show Message',
                    value: QR_CODE_ACTION_TYPES.SHOW_MESSAGE,
                },
                {
                    label: 'Open Link',
                    value: QR_CODE_ACTION_TYPES.OPEN_LINK,
                },
            ],
            QR_CODE_WIDTH_OPTIONS: [
                {
                    label: '1200 X 1200',
                    value: 1200,
                },
                {
                    label: '1800 X 1800',
                    value: 1800,
                },
            ],
        },
        LABEL: {
            EQUALS: 'EQUALS',
            NOT_EQUALS: 'NOT_EQUALS',
            LABEL_RULE_STATIC_FIELD: {
                CITY: 'CITY',
                TITLE: 'TITLE',
                COMPANY: 'COMPANY',
                TIMEZONE: 'TIMEZONE',
                LAST_NAME: 'LAST_NAME',
                EMAIL: 'EMAIL',
                EMAIL_EQUALS: 'EMAIL_EQUALS',
                EMAIL_NOT_EQUALS: 'EMAIL_NOT_EQUALS',
            },
        },
        MATERIAL: {
            TYPE_DROPDOWN_OPTIONS: [
                {
                    value: 'Image',
                    label: 'Image',
                    data: {
                        fileTypesAllowed: allowedImageTypes,
                    },
                    typeOfOption: 'file',
                },
                {
                    value: 'PDF',
                    label: 'PDF',
                    data: {
                        fileTypesAllowed: ['pdf'],
                    },
                    typeOfOption: 'file',
                },
                {
                    value: 'Video',
                    label: 'Video',
                    data: {
                        fileTypesAllowed: allowedVideoTypes,
                    },
                    typeOfOption: 'file',
                },
                {
                    value: 'Audio',
                    label: 'Audio',
                    data: {
                        fileTypesAllowed: ['mp3', 'm4a'],
                    },
                    typeOfOption: 'file',
                },
                {
                    value: 'Link',
                    label: 'Link',
                    data: {
                        fileTypesAllowed: [],
                    },
                    typeOfOption: 'input',
                },
            ],
        },
        ENGAGEMENT_TOOLS: {
            TYPE_DROPDOWN_OPTIONS: [
                {
                    label: ENGAGEMENT_TOOLS_TYPES_LABELS.SURVEY,
                    value: ENGAGEMENT_TOOLS_TYPES.SURVEY,
                    data: {
                        apiEndPoint: apiEndPointsForEngagementTools.SURVEYS,
                    },
                },
                {
                    label: ENGAGEMENT_TOOLS_TYPES_LABELS.POLL,
                    value: ENGAGEMENT_TOOLS_TYPES.POLL,
                    data: {
                        apiEndPoint: apiEndPointsForEngagementTools.POLLS,
                    },
                },
                {
                    label: ENGAGEMENT_TOOLS_TYPES_LABELS.QA,
                    value: ENGAGEMENT_TOOLS_TYPES.QA,
                    data: {
                        apiEndPoint: apiEndPointsForEngagementTools.QAS,
                    },
                },
                {
                    label: ENGAGEMENT_TOOLS_TYPES_LABELS.QUIZ,
                    value: ENGAGEMENT_TOOLS_TYPES.QUIZ,
                    data: {
                        apiEndPoint: apiEndPointsForEngagementTools.QUIZ,
                    },
                },
            ],
            ANONYMITY_DROPDOWN_OPTIONS: [
                {
                    label: ANONIMITY_LABELS.ANONYMOUS,
                    value: 'ANONYMOUS',
                    data: {},
                },
                {
                    label: ANONIMITY_LABELS.NOT_ANONYMOUS,
                    value: 'NOT_ANONYMOUS',
                    data: {},
                },
                {
                    label: ANONIMITY_LABELS.ALLOW_ANONYMOUS,
                    value: 'ALLOW_ANONYMOUS',
                    data: {},
                },
            ],
            QUESTIONS_TYPE_DROPDOWN_OPTIONS: [
                {
                    label: 'Short answer',
                    value: ENGAGEMENT_TOOL_QUESTION_TYPES.TEXTBOX,
                    data: {},
                },
                {
                    label: 'Multiple choice - radio',
                    value: ENGAGEMENT_TOOL_QUESTION_TYPES.MULTIPLE_CHOICE,
                    data: {},
                },
                {
                    label: 'Multiple choice - dropdown',
                    value: ENGAGEMENT_TOOL_QUESTION_TYPES.MULTIPLE_CHOICE,
                    data: {
                        isShowOptionsInDropdown: true,
                    },
                },
                {
                    label: 'Checkboxes',
                    value: ENGAGEMENT_TOOL_QUESTION_TYPES.CHECKBOXES,
                    data: {},
                },
                {
                    label: 'Linear scale',
                    value: ENGAGEMENT_TOOL_QUESTION_TYPES.LINEAR_SCALE,
                    data: {},
                },
                {
                    label: 'Text field',
                    value: ENGAGEMENT_TOOL_QUESTION_TYPES.TEXT_FIELD,
                    data: {},
                },
            ],
            QUIZ_QUESTIONS_TYPE_DROPDOWN_OPTIONS: [
                {
                    label: 'Multiple choice',
                    value: ENGAGEMENT_TOOL_QUESTION_TYPES.MULTIPLE_CHOICE,
                    data: {},
                },
            ],
            LINEARSCALE_DROPDOWN_OPTIONS: [
                {
                    label: '2',
                    value: 2,
                },
                {
                    label: '3',
                    value: 3,
                },
                {
                    label: '4',
                    value: 4,
                },
                {
                    label: '5',
                    value: 5,
                },
                {
                    label: '6',
                    value: 6,
                },
                {
                    label: '7',
                    value: 7,
                },
                {
                    label: '8',
                    value: 8,
                },
                {
                    label: '9',
                    value: 9,
                },
                {
                    label: '10',
                    value: 10,
                },
            ],
        },
        COMMUNICATIONS: {
            SCHEDULE: {
                NOW: 'NOW',
                DATE_AND_TIME: 'DATE_AND_TIME',
                SCHEDULE_DATE: DATE_AND_TIME.scheduleDate,
            },
            COMMUNICATION_TYPE: {
                EMAIL: 'EMAIL',
                PUSH_NOTIFICATION: 'PUSH_NOTIFICATION',
            },
        },
        ORGANIZATION: {
            STATUS_DROPDOWN_OPTIONS: [
                {
                    value: 'DRAFT',
                    label: 'Draft',
                },
                {
                    value: 'PUBLISHED',
                    label: 'Published',
                },
            ],
        },
        DIRECTORIES: {
            SORTED_BY_OPTIONS: [
                {
                    value: 'firstName',
                    label: 'First name',
                },
                {
                    value: 'lastName',
                    label: 'Last name',
                },
            ],
        },
        APP_DESIGNER: {
            PAGES: {
                PAGE_OPTIONS: [
                    {
                        value: pageTypes.NORMAL_PAGE,
                        label: 'Custom page',
                        tabLabel: 'Custom page',
                        isShowSettingsIcon: true,
                    },
                    {
                        value: pageTypes.FORM_PAGE,
                        label: 'Form page',
                        tabLabel: 'Forms',
                        isShowSettingsIcon: false,
                    },
                    {
                        value: pageTypes.DIRECTORY_PAGE,
                        label: 'Directory page',
                        tabLabel: 'Directories',
                        isShowSettingsIcon: false,
                    },
                    {
                        value: pageTypes.ACTIVITY_FEED_PAGE,
                        label: 'Activity feed',
                        tabLabel: 'Activity feeds',
                        isShowSettingsIcon: false,
                    },
                    {
                        value: pageTypes.EXTERNAL_QR_CODE_PAGE,
                        label: 'External QR code',
                        tabLabel: 'External QR codes',
                        isShowSettingsIcon: false,
                    },
                ],
                FORM_OPTIONS: [
                    {
                        value: formTypes.NEW,
                        label: 'New',
                    },
                    {
                        value: formTypes.EXISTING,
                        label: 'Existing',
                    },
                ],
                WEB_PAGE_OPTIONS: [
                    {
                        value: pageTypes.NORMAL_PAGE,
                        label: 'Custom page',
                        tabLabel: 'Custom page',
                        isShowSettingsIcon: true,
                    },
                ],
            },
            ADD_MODULE_DROPDOWN_OPTIONS: [
                {
                    label: 'Container',
                    name: MODULE_ITEMS.CONTAINER,
                    data: {},
                },
                {
                    label: 'Survey',
                    name: ENGAGEMENT_TOOLS_TYPES.SURVEY,
                    data: {
                        apiEndPoint: apiEndPointsForEngagementTools.SURVEYS,
                    },
                },
                {
                    label: 'Q & A',
                    name: ENGAGEMENT_TOOLS_TYPES.QA,
                    data: {
                        apiEndPoint: apiEndPointsForEngagementTools.QAS,
                    },
                },
                {
                    label: 'Poll',
                    name: ENGAGEMENT_TOOLS_TYPES.POLL,
                    data: {
                        apiEndPoint: apiEndPointsForEngagementTools.POLLS,
                    },
                },
                // uncomment once quiz feature need to implement
                // {
                //     label: ENGAGEMENT_TOOLS_TYPES_LABELS.QUIZ,
                //     name: ENGAGEMENT_TOOLS_TYPES.QUIZ,
                //     data: {
                //         apiEndPoint: apiEndPointsForEngagementTools.QUIZ,
                //     },
                // },
                // uncomment once discussion feature implemented
                // {
                //     label: 'Discussion',
                //     name: ENGAGEMENT_TOOLS_TYPES.QA,
                //     data: {
                //         apiEndPoint: apiEndPointsForEngagementTools.QAS,
                //     },
                // },
                // uncomment if need to provide map module feature
                // {
                //     label: 'Map',
                //     name: MODULE_ITEMS.MAP,
                //     data: {},
                // },
                {
                    label: 'Directory',
                    name: MODULE_ITEMS.DIRECTORY,
                    data: {},
                },
                {
                    label: 'Document',
                    name: MODULE_ITEMS.DOCUMENT,
                    data: {},
                },
                {
                    label: 'Library',
                    name: MODULE_ITEMS.LIBRARY,
                    data: {
                        isMultiSelectNameOptions: true,
                    },
                },
                {
                    label: 'Linked page',
                    name: MODULE_ITEMS.LINKED_PAGE,
                    data: {},
                },
                {
                    label: 'Text',
                    name: MODULE_ITEMS.TEXT,
                    data: {},
                },
                {
                    label: 'Image',
                    name: MODULE_ITEMS.IMAGE,
                    data: {},
                },
                {
                    label: 'Video',
                    name: MODULE_ITEMS.VIDEO,
                    data: {},
                },
                {
                    label: 'My agenda',
                    name: MODULE_ITEMS.MY_AGENDA,
                    data: {},
                },
            ],
            ADD_MODULE_DROPDOWN_OPTIONS_FOR_MENU: [
                {
                    label: 'Link',
                    name: MODULE_ITEMS.LINKED_PAGE,
                    data: {},
                },
            ],
            WEB_ADD_MODULE_DROPDOWN_OPTIONS: [
                {
                    label: 'Text',
                    id: MODULE_ITEMS.TEXT,
                    data: {},
                },
                {
                    label: 'Image',
                    id: MODULE_ITEMS.IMAGE,
                    data: {},
                },
                {
                    label: 'Video',
                    id: MODULE_ITEMS.VIDEO,
                    data: {},
                },
                {
                    label: 'Link',
                    id: MODULE_ITEMS.LINKED_PAGE,
                    data: {},
                },
            ],
            WEB_LINK_TYPE_DROPDOWN_OPTIONS: [
                {
                    label: 'Normal',
                    value: WEBSITE_LINK_TYPES.NORMAL,
                },
                {
                    label: 'Image',
                    value: WEBSITE_LINK_TYPES.IMAGE,
                },
                {
                    label: 'Button',
                    value: WEBSITE_LINK_TYPES.BUTTON,
                },
            ],
            DIRECTORY_OPTIONS: [
                {
                    label: `All ${participantLabels.PARTICIPANTS}`,
                    value: directoryItems.ALL_PARTICIPANTS,
                },
                {
                    label: 'All Speakers',
                    value: directoryItems.ALL_SPEAKERS,
                },
                {
                    label: 'Business Card Exchange',
                    value: directoryItems.BUSINESS_CARD_EXCHANGED,
                },
                {
                    label: 'Users available for meeting',
                    value: directoryItems.USERS_AVAILABLE_FOR_MEETINGS,
                },
                {
                    label: 'Custom',
                    value: directoryItems.CUSTOM,
                },
            ],
            LINKED_PAGE_OPTIONS_FOR_NAVIGATION: [
                {
                    label: 'Home',
                    value: linkPageItems.HOME,
                },
            ],
            LINKED_PAGE_OPTIONS: [
                {
                    label: 'Access control',
                    value: linkPageItems.ACCESS_CONTROL,
                },
                {
                    label: 'Activity feed',
                    value: linkPageItems.ACTIVITY_FEED_PAGE,
                },
                {
                    label: 'Business card exchange scanner',
                    value: linkPageItems.BUSINESS_CARD_EXCHANGE_SCANNER,
                },
                {
                    label: 'Custom page',
                    value: linkPageItems.PAGE_OPTION,
                },
                {
                    label: 'Directory page',
                    value: linkPageItems.DIRECTORY_PAGE,
                },
                {
                    label: 'Engagement Tool',
                    value: linkPageItems.ENGAGEMENT_TOOL,
                },
                {
                    label: 'External qr code',
                    value: linkPageItems.EXTERNAL_QR_CODE_PAGE,
                },
                {
                    label: 'Form page',
                    value: linkPageItems.FORM_PAGE,
                },
                {
                    label: 'Game page',
                    value: linkPageItems.GAME_PAGE,
                },
                {
                    label: 'Material',
                    value: linkPageItems.MATERIAL,
                },
                {
                    label: 'My agenda',
                    value: linkPageItems.MY_AGENDA,
                },
                {
                    label: 'The scanner page',
                    value: linkPageItems.SCANNER,
                },
                {
                    label: 'URL',
                    value: linkPageItems.URL,
                },
                {
                    label: 'User profile',
                    value: linkPageItems.USER_PROFILE,
                },
                {
                    label: 'Edit profile',
                    value: linkPageItems.EDIT_PROFILE,
                },
            ],
            LINKED_PAGE_OPTIONS_FOR_WEB_DESIGNER: [
                {
                    label: 'URL',
                    value: linkPageItems.URL,
                },
                {
                    label: 'Custom page',
                    value: linkPageItems.PAGE_OPTION,
                },
                {
                    label: 'Directory page',
                    value: linkPageItems.DIRECTORY_PAGE,
                },
                {
                    label: 'Form page',
                    value: linkPageItems.FORM_PAGE,
                },
                {
                    label: 'Login page',
                    value: linkPageItems.LOGIN_PAGE,
                },
                // Removed registration from link module
                // {
                //     label: 'Registration page',
                //     value: linkPageItems.REGISTRATION_PAGE,
                // },
            ],
            FONT_STYLE_OPTIONS: [
                {
                    label: 'Large title',
                    value: 'h1',
                },
                {
                    label: 'Title 1',
                    value: 'h2',
                },
                {
                    label: 'Title 2',
                    value: 'h3',
                },
                {
                    label: 'Title 3',
                    value: 'h4',
                },
                {
                    label: 'Heading',
                    value: 'h5',
                },
                {
                    label: 'Sub heading',
                    value: 'h6',
                },
            ],
            FONT_FAMILY_OPTIONS: [
                {
                    label: 'Serif',
                    value: 'serif',
                },
                {
                    label: 'Sans Serif',
                    value: 'sans-serif',
                },
            ],
            FONT_SIZE_OPTIONS: [
                {
                    label: 'Small',
                    value: 'SMALL',
                },
                {
                    label: 'Medium',
                    value: 'MEDIUM',
                },
                {
                    label: 'Large',
                    value: 'LARGE',
                },
            ],
        },
        REGISTRATIONS_FORMS: {
            STATUS: {
                OPEN: 'OPEN',
                CLOSED: 'CLOSED',
                SCHEDULED: 'SCHEDULED',
            },
            TYPE_OF_FORM: {
                USER: 'User',
            },
            OPERATORS: [
                {
                    label: 'Equal',
                    value: operators.EQUAL,
                    data: {
                        fields: [
                            'BOOLEAN',
                            'STRING',
                            'NUMBER',
                            'DATE',
                            'TIME',
                            'CURRENCY',
                            'PROFILE_LINK',
                        ],
                    },
                },
                {
                    label: 'Not equal',
                    value: operators.NOT_EQUAL,
                    data: {
                        fields: [
                            'BOOLEAN',
                            'STRING',
                            'NUMBER',
                            'DATE',
                            'TIME',
                            'CURRENCY',
                            'PROFILE_LINK',
                        ],
                    },
                },
                {
                    label: 'Greater than or equal to',
                    value: operators.GREATER_THAN_EQUAL,
                    data: {
                        fields: ['NUMBER', 'DATE', 'TIME', 'CURRENCY'],
                    },
                },
                {
                    label: 'Less than or equal to',
                    value: operators.LESS_THAN_EQUAL,
                    data: {
                        fields: ['NUMBER', 'DATE', 'TIME', 'CURRENCY'],
                    },
                },
                {
                    label: 'Greater than',
                    value: operators.GREATER_THAN,
                    data: {
                        fields: ['NUMBER', 'DATE', 'TIME', 'CURRENCY'],
                    },
                },
                {
                    label: 'Less than',
                    value: operators.LESS_THAN,
                    data: {
                        fields: ['NUMBER', 'DATE', 'TIME', 'CURRENCY'],
                    },
                },
                {
                    label: 'Includes',
                    value: operators.INCLUDES,
                    data: {
                        fields: ['ENUM'],
                    },
                },
            ],
        },
        REPORTS: {
            AVAILABLE_REPORTS_OPTIONS: [
                {
                    label: `Downloaded app version's`,
                    value: reportTypes.ATTENDEES_DOWNLOADED_APP_VERSIONS_REPORT,
                    category: eventReportsCategories.EVENT,
                },
                {
                    label: 'Event accessed',
                    value: reportTypes.EVENT_ACCESSED_REPORT,
                    category: eventReportsCategories.EVENT,
                },
                {
                    label: 'Meetings',
                    value: reportTypes.MEETINGS_REPORT,
                    category: eventReportsCategories.EVENT,
                },
                {
                    label: `Attendee's photo`,
                    value: reportTypes.ATTENDEES_PHOTO_REPORT,
                    category: eventReportsCategories.ATTENDEE,
                },
                {
                    label: `Enrollment`,
                    value: reportTypes.ENROLLMENT_REPORT_FOR_SESSIONS_AND_BREAKOUTS,
                    category: eventReportsCategories.ATTENDEE,
                },
                {
                    label: `Magic Links`,
                    value: reportTypes.MAGIC_LINKS_REPORT,
                    category: eventReportsCategories.ATTENDEE,
                },
                {
                    label: 'Registration',
                    value: reportTypes.REGISTRATION_REPORT,
                    category: eventReportsCategories.ATTENDEE,
                },
                // TODO: uncomment once aggregated survey result screen support needs to add
                // {
                //     label: 'Aggregated survey results',
                //     value: reportTypes.AGGREGATED_SURVEY_RESULT_REPORT,
                // },
                // {
                //     label: 'Aggregated poll results',
                //     value: reportTypes.AGGREGATED_POLL_RESULT_REPORT,
                // },
                {
                    label: 'Poll results',
                    value: reportTypes.POLL_RESULT_REPORT,
                    category: eventReportsCategories.ENGAGEMENT_TOOLS,
                },
                {
                    label: 'Q&A',
                    value: reportTypes.QA_REPORT,
                    category: eventReportsCategories.ENGAGEMENT_TOOLS,
                },
                {
                    label: 'Quiz results',
                    value: reportTypes.QUIZ_RESULT_REPORT,
                    category: eventReportsCategories.ENGAGEMENT_TOOLS,
                },
                {
                    label: 'Survey results',
                    value: reportTypes.SURVEY_RESULT_REPORT,
                    category: eventReportsCategories.ENGAGEMENT_TOOLS,
                },
                {
                    label: 'Custom qr codes',
                    value: reportTypes.QR_CODE_REPORT,
                    category: eventReportsCategories.QR,
                },
                {
                    label: 'Session qr codes',
                    value: reportTypes.SESSION_QR_CODE_REPORT,
                    category: eventReportsCategories.QR,
                },
            ],
            AVAILABLE_HUB_REPORTS_OPTIONS: [
                {
                    label: 'Attendees report',
                    value: reportTypes.ATTENDEES_REPORT,
                    category: hubReportsCategories.EVENT,
                },
            ],
        },
        THIRD_PARTY_IMPORT: {
            INTEGRATION_TYPE_DROPDOWN_OPTIONS: [
                {
                    label: 'Cvent (REST API)',
                    value: 'CVENT',
                },
            ],
            MODEL_DROPDOWN_OPTIONS: [
                {
                    label: 'Attendees',
                    value: 'ATTENDEES',
                },
            ],
            SCHEDULE_TIME_DROPDOWN_OPTIONS: [
                {
                    label: 'Every 30 minutes',
                    value: 30,
                },
                {
                    label: 'Every hour',
                    value: 60,
                },
                {
                    label: 'Every 90 minutes',
                    value: 90,
                },
                {
                    label: 'Every 2 hours',
                    value: 120,
                },
                {
                    label: 'Every 3 hours',
                    value: 180,
                },
                {
                    label: 'Every 6 hours',
                    value: 360,
                },
                {
                    label: 'Every 12 hours',
                    value: 720,
                },
                {
                    label: 'Once a day',
                    value: 1440,
                },
            ],
        },
        MEETINGS: {
            INTERVALS_DROPDOWN_OPTIONS: generateIntervalArray(120, 5),
        },
        GAMIFICATIONS: {
            TASKS: {
                TYPE_OPTIONS: [
                    {
                        label: gameTaskTypeOptionsLabels.SURVEY,
                        value: gameTaskTypeOptionsValues.SURVEY,
                        data: {
                            type: 'ANY_OR_ENUM',
                            enumValues: [],
                            labelForSpecificValue: 'Choose specific surveys',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.QUIZ,
                        value: gameTaskTypeOptionsValues.QUIZ,
                        data: {
                            type: 'ANY_OR_ENUM',
                            enumValues: [],
                            labelForSpecificValue: 'Choose specific quizzes',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.ACTIVITY_FEED,
                        value: gameTaskTypeOptionsValues.ACTIVITY_FEED,
                        data: {
                            type: 'ANY_OR_ENUM',
                            enumValues: [],
                            labelForSpecificValue: 'Choose specific feeds',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.QA,
                        value: gameTaskTypeOptionsValues.QA,
                        data: {
                            type: 'ANY_OR_ENUM',
                            enumValues: [],
                            labelForSpecificValue: 'Choose specific Q&A',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.VIEW_DOCUMENT,
                        value: gameTaskTypeOptionsValues.VIEW_DOCUMENT,
                        data: {
                            type: 'ANY_OR_ENUM',
                            enumValues: [],
                            labelForSpecificValue: 'Choose specific documents',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.ATTEND_SESSION,
                        value: gameTaskTypeOptionsValues.ATTEND_SESSION,
                        data: {
                            type: 'ANY_OR_ENUM',
                            enumValues: [],
                            labelForSpecificValue: 'Choose specific sessions',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.REGISTER_SESSION,
                        value: gameTaskTypeOptionsValues.REGISTER_SESSION,
                        data: {
                            type: 'ANY_OR_ENUM',
                            enumValues: [],
                            labelForSpecificValue:
                                'Choose specific enrollables',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.POPULATE_PROFILE,
                        value: gameTaskTypeOptionsValues.POPULATE_PROFILE,
                        data: {
                            type: 'BOOLEAN',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.REGISTRATION_FORM,
                        value: gameTaskTypeOptionsValues.REGISTRATION_FORM,
                        data: {
                            type: 'ANY_OR_ENUM',
                            enumValues: [],
                            labelForSpecificValue: 'Choose specific forms',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.SIGNUP_MEETING,
                        value: gameTaskTypeOptionsValues.SIGNUP_MEETING,
                        data: {
                            type: 'ANY_OR_ENUM',
                            enumValues: [],
                            labelForSpecificValue: 'Choose specific meetings',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.DOWNLOAD_APP,
                        value: gameTaskTypeOptionsValues.DOWNLOAD_APP,
                        data: {
                            type: 'BOOLEAN',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.SCAN_QR,
                        value: gameTaskTypeOptionsValues.SCAN_QR,
                        data: {
                            type: 'ANY_OR_ENUM',
                            enumValues: [],
                            labelForSpecificValue: 'Choose specific QR codes',
                        },
                    },
                    {
                        label: gameTaskTypeOptionsLabels.BUSINESS_CARD_EXCHANGE,
                        value: gameTaskTypeOptionsValues.BUSINESS_CARD_EXCHANGE,
                        data: {
                            type: 'ANY_OR_ENUM',
                            enumValues: [],
                            labelForSpecificValue: 'Choose specific directory',
                        },
                    },
                ],
            },
        },
        APP_VERSIONS: {
            PLATFORM_DROPDOWN_OPTIONS: [
                {
                    value: 'iOS',
                    label: 'iOS',
                },
                {
                    value: 'Android',
                    label: 'Android',
                },
            ],
        },
    },
};

// default configurations for Q&A
export const defaultDisplayTypeIsScroll = true;
export const defaultContinuousScroll = false;
export const defaultSortOption = sortOptions[1].value;
export const defaultScrollValue = 2;
export const defaultEntriesToShow = displayQAEntriesTypes.SHOW_ALL_ENTRIES;
const optionsForAnonymityDropdown =
    constants.SCREENS.ENGAGEMENT_TOOLS.ANONYMITY_DROPDOWN_OPTIONS;
export const defaultAnonimityValue = optionsForAnonymityDropdown[1]?.value;

export default constants;
