import QRCode from 'qrcode';
import JSPdf from 'jspdf';
import JSZipUtils from 'jszip-utils';
import JSZip from 'jszip';
import {
    downloadFileFromBlobData,
    downloadFileFromUrl,
} from 'utils/downloadFile';

// downloadQRCodes: function to download qr codes based on records which contains value and title
export const downloadQRCodes = async ({
    recordes,
    fileName = 'QR Codes.zip',
    setIsLoading = () => null,
    type = 'pdf',
    width,
}) => {
    if (type === 'pdf') {
        const pdf = new JSPdf();
        recordes.map(async (record, i) => {
            await QRCode.toDataURL(
                record.value,
                {
                    width,
                },
                (err, url) => {
                    pdf.addImage(url, 'PNG', 32, 65, 150, 150);
                    pdf.text(record?.title, 15, 20);
                    if (i + 1 !== recordes.length) {
                        pdf.addPage();
                    }
                    if (err) setIsLoading(false);
                }
            );
        });
        await pdf.save(fileName);
        setIsLoading(false);
    } else if (type === 'pngs') {
        if (recordes.length === 1) {
            recordes.map(async (record, i) => {
                await QRCode.toDataURL(
                    record.value,
                    {
                        width,
                    },
                    async (err, url) => {
                        await downloadFileFromUrl(url, record?.title);
                        if (err) setIsLoading(false);
                    }
                );
            });
            setIsLoading(false);
        } else {
            const zip = new JSZip();
            let count = 0;
            const images = [];
            recordes.map(async (record, i) => {
                await QRCode.toDataURL(
                    record.value,
                    {
                        width,
                    },
                    async (err, url) => {
                        images.push(url);
                        if (err) setIsLoading(false);
                    }
                );
            });
            images.forEach((url, index) => {
                const filename = recordes[index]?.title || 'qr-code';
                // loading a file and add it in a zip file
                JSZipUtils.getBinaryContent(url, async (err, data) => {
                    if (err) {
                        throw err; // or handle the error
                    }
                    zip.file(filename, data, { binary: true });
                    count += 1;
                    if (count === images.length) {
                        const zipFile = await zip.generateAsync({
                            type: 'blob',
                        });
                        downloadFileFromBlobData(
                            window.URL.createObjectURL(zipFile),
                            fileName
                        );
                    }
                });
            });
            setIsLoading(false);
        }
    }
};

export const getQRCode = async (value, setQRCode) => {
    await QRCode.toDataURL(value, (err, url) => {
        if (setQRCode) setQRCode(url);
    });
};
